// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fxjFwpoLp1D5swAzsysj {\n  margin: 0 auto;\n  /* width: 98%; */\n  /* width: 95%; */\n  /* max-width: 40rem; */\n  /* height: 100vh; */\n  /* width: 98%; */\n  width: 100%;\n  min-height: 100vh;\n}\n\n.O_mVdNBKyNT2UJ1A71Vz {\n  /* min-height: 100vh; */\n}\n\n.NZ_joUE9Uz_3wilLQ490 {\n  /* min-height: 100vh; */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC","sourcesContent":[".main {\n  margin: 0 auto;\n  /* width: 98%; */\n  /* width: 95%; */\n  /* max-width: 40rem; */\n  /* height: 100vh; */\n  /* width: 98%; */\n  width: 100%;\n  min-height: 100vh;\n}\n\n.AppStretch {\n  /* min-height: 100vh; */\n}\n\n.AppContainer {\n  /* min-height: 100vh; */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "fxjFwpoLp1D5swAzsysj",
	"AppStretch": "O_mVdNBKyNT2UJ1A71Vz",
	"AppContainer": "NZ_joUE9Uz_3wilLQ490"
};
export default ___CSS_LOADER_EXPORT___;
