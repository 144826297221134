import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_SLIDEUPDATETIME: (state, payload) => {
      return { 
        // ...state,
        presentationStore : {
          ...state.presentationStore,
          slideUpdateTime: payload, 
        }
      };
    },
    SET_SLIDEFILESIZE: (state, payload) => {
      return { 
        // ...state,
        presentationStore : {
          ...state.presentationStore,
          slideFileSize: payload, 
        }
      };
    },
    SET_SHOWSLIDEABOUTKURASTORAGE: (state, payload) => {
      return { 
        // ...state,
        presentationStore : {
          ...state.presentationStore,
          showSlideAboutKuraStorage: payload, 
        }
      };
    },
  };

  initStore(actions, {
    presentationStore: {
      slideUpdateTime: 0,
      slideFileSize: 0,
      showSlideAboutKuraStorage: false,
    }
  });
};

export default configureStore;
