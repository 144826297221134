// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TL9VNzeP2p8HSGeZMkxH {\n  padding: 1rem;\n}\n\n.FRHO_Kgog9KwzrsNS9m2 {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  padding: 1rem 0;\n}\n\n.UnwGa4nFRuQy7Jgi1Mnb {\n  font-size: 1.25rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/NotPageFound/NotPageFound.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".notPageFoundContent {\n  padding: 1rem;\n}\n\n.notPageFoundButtons {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  padding: 1rem 0;\n}\n\n.notPageFoundAboutLink {\n  font-size: 1.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notPageFoundContent": "TL9VNzeP2p8HSGeZMkxH",
	"notPageFoundButtons": "FRHO_Kgog9KwzrsNS9m2",
	"notPageFoundAboutLink": "UnwGa4nFRuQy7Jgi1Mnb"
};
export default ___CSS_LOADER_EXPORT___;
