import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LuckyExcel from 'luckyexcel'


import { useStore } from '../../../../../hook-store/store';

// import { mimeTypes } from '../../../../utils/file/mime-type';

// import { BACKEND_URL } from '../../../../App';
// import './App.css';

import '../../../TextEditor/FileControl/EditorFileMenu.css';

function ImportExcel() {

  const [t] = useTranslation('translation');

  const excelImportRef = useRef(null);

  const [store, dispatch] = useStore();
  const { loadedData } = store.toolsStore;
  const { isAuth } = store.uiStore;

  // const [sheetTitle, setSheetTitle] = useState('');
  // const [sheetTitleInput, SetSheetTitleInput] = useState('');

  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (loadedData) {
  //     console.log(loadedData);
  //     const ssData = JSON.parse(loadedData);
  //     console.log(ssData);
  //     setSheetTitle(ssData.title);
  //     console.log( window.luckysheet.getAllSheets() );
  //     console.log( window.luckysheet.getAllSheets().length );
  //   }
  // },[loadedData]);

  const onExcelImportClick = () => {
    excelImportRef.current.click(); 
  };

  // const sheetTitleInputHandler = (event) => {
  //   SetSheetTitleInput(event.target.value);
  // };



  const excelImportHandler = (event) => {
    const file = event.target.files[0];

    // After getting the xlsx file
    LuckyExcel.transformExcelToLucky(file, 
      function(exportJson, luckysheetfile){
          // Get the worksheet data after conversion
          // console.log(exportJson, luckysheetfile);

          const forLoad = {
            title: 'sheet-title',
            data: exportJson.sheets,
          };

          console.log('forLoad',forLoad);
          dispatch('SET_LOADEDDATA', JSON.stringify(forLoad));
      },
      function(error){
          // handle error if any thrown
          console.log(error);
      }
    )
  }







  return (
    <Fragment>
        <input 
          type='file'
          ref={excelImportRef} 
          style={{display: 'none'}}
          accept='.xlsx'
          onChange={excelImportHandler}
        />
        <button className='editorFileMeunButton'
          onClick={onExcelImportClick}
        >
          {t('sheet04', 'import Excel file')}
        </button>
    </Fragment>
  );
}

export default ImportExcel;
