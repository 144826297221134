import React, { Fragment, useState, useEffect } from 'react';

// import download from 'downloadjs';


import SheetFileControl from './SheetFileControl/SheetFileControl';
import { useStore } from '../../../hook-store/store';


// import './App.css';

function Luckysheet() {

  const [store, dispatch] = useStore();
  const { loadedData } = store.toolsStore;

  useEffect(() => {
      if (loadedData) {
        const ssData = JSON.parse(loadedData);
        console.log(ssData);

        const luckysheet = window.luckysheet;
        luckysheet.create({
            container: "luckysheet",
            plugins:['chart'],
  
            ////  https://dream-num.github.io/LuckysheetDocs/guide/config.html#configuration-item
            title: ssData.title,
            data: ssData.data,
            // showtoolbar: true, // The default is true, you can leave it unset
            // showtoolbarConfig: {
            //     image: false,
            //     print: false,
            // },
            showinfobar: false,
            // showsheetbar: false,
        });
      } 

      else {
        const luckysheet = window.luckysheet;
        luckysheet.create({
            container: "luckysheet",
            plugins:['chart'],
  
            ////  https://dream-num.github.io/LuckysheetDocs/guide/config.html#configuration-item
            title: '',
            // data: ssData.data,
            // showtoolbar: true, // The default is true, you can leave it unset
            // showtoolbarConfig: {
            //     image: false,
            //     print: false,
            // },
            showinfobar: false,
            // showsheetbar: false,
        });
      }

      // const luckysheet = window.luckysheet;
      // luckysheet.create({
      //     container: "luckysheet",
      //     plugins:['chart'],

      //     ////  https://dream-num.github.io/LuckysheetDocs/guide/config.html#configuration-item
      //     title: 'luckysheet-react',
      //     // showtoolbar: true, // The default is true, you can leave it unset
      //     // showtoolbarConfig: {
      //     //     image: false,
      //     //     print: false,
      //     // },
      //     // showinfobar: false,
      //     // showsheetbar: false,
      // });


      // testFetch();
  },[loadedData]);

  useEffect(() => {
    const statAreaEls = document.getElementsByClassName('luckysheet-stat-area');
    // console.log('stateAreaEls', statAreaEls, window.luckysheet);
    if (statAreaEls && statAreaEls.length > 0) {
      statAreaEls[0].style.background = '#d4d4d4';
    }
  },[window.luckysheet]);
  // const testFetch = async () => {
  //   try {
  //     const result = await fetch('https://kmpartner-do-test-dev-space.sfo2.digitaloceanspaces.com/lsheet-data%20(1).txt');

  //     const resData = await result.json();
  
  //     console.log(result, resData);

  //     if (resData) {
  //       const luckysheet = window.luckysheet;
  //       luckysheet.create({
  //           container: "luckysheet",
  //           plugins:['chart'],
  
  //           ////  https://dream-num.github.io/LuckysheetDocs/guide/config.html#configuration-item
  //           // title: 'luckysheet-react',
  //           title: resData.title,
  //           // showtoolbar: true, // The default is true, you can leave it unset
  //           // showtoolbarConfig: {
  //           //     image: false,
  //           //     print: false,
  //           // },
  //           // showinfobar: false,
  //           // showsheetbar: false,
  //           data: resData.data
  //       });
  //     }
  //     return resData;

  //   } catch(err) {
  //     console.log(err);
  //     throw err;
  //   }

  // };

  const luckyCss = {
      margin: '0px',
      padding: '0px',
      position: 'absolute',
      width: '100%',
      // height: '100%',
      // left: '0px',
      // top: '0px',

      height: 'calc(100% - 80px)',
      top: '30px',
      // border:"1px solid red",
  };

  return (
    <Fragment>
      <div>
        {/* <SheetFile /> */}
        <SheetFileControl />
      </div>
      <div
        id="luckysheet"
        style={luckyCss}
      >
      </div>
    </Fragment>
  );
}

export default Luckysheet;
