"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundObject = void 0;
var fabric_1 = require("fabric");
var defaultShadow = {
    blur: 10,
    color: "#C7C7C7",
    offsetX: 0,
    offsetY: 0,
};
// @ts-ignore
var BackgroundObject = /** @class */ (function (_super) {
    __extends(BackgroundObject, _super);
    function BackgroundObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BackgroundObject.prototype.initialize = function (options) {
        var _this = this;
        var shadowOptions = options.shadow ? options.shadow : defaultShadow;
        var shadow = new fabric_1.fabric.Shadow(__assign({ affectStroke: false }, shadowOptions));
        _super.prototype.initialize.call(this, __assign(__assign({}, options), { selectable: false, hasControls: false, hasBorders: false, lockMovementY: true, lockMovementX: true, strokeWidth: 0, evented: true, hoverCursor: "default", shadow: shadow }));
        this.on("mouseup", function (_a) {
            var target = _a.target;
            var activeSelection = _this.canvas.getActiveObject();
            if (!activeSelection && target === _this) {
                _this.canvas.fire("background:selected");
            }
        });
        return this;
    };
    BackgroundObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    BackgroundObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    BackgroundObject.fromObject = function (options, callback) {
        return callback && callback(new fabric_1.fabric.Background(options));
    };
    BackgroundObject.type = "Background";
    return BackgroundObject;
}(fabric_1.fabric.Rect));
exports.BackgroundObject = BackgroundObject;
fabric_1.fabric.Background = fabric_1.fabric.util.createClass(BackgroundObject, {
    type: BackgroundObject.type,
});
fabric_1.fabric.Background.fromObject = BackgroundObject.fromObject;
