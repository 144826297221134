import React, { Fragment, useEffect, useState } from 'react';
// import openSocket from 'socket.io-client';
import { io } from "socket.io-client";

import { useStore } from '../../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util'
import { getLoadObjectInfo } from '../../../utils/tools-bucket-object/tools-bucket-object-util';
import { getLoadData } from '../../../utils/tools-bucket-object/tools-bucket-object-util';
import { BACKEND_URL, TOOLSBACKEND_URL } from '../../../App';

// import classes from './PostSelect.module.css';

function FileUpdateSocket(props) {
  // const { } = props;

  const [store, dispatch] = useStore();
  const { 
    loadedObjectInfo,
    updatedShareObjectInfo,
   } = store.toolsStore;
  // const { isAuth } = store.uiStore;
  
  // const [oldKey, setOldKey] = useState('');
  const [updatedObjectInfo, setUpdatedObjectInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    console.log('updatedObjectInfo loadedObjectInfo', updatedShareObjectInfo, loadedObjectInfo);

    shareObjectUpdateHandler(updatedShareObjectInfo, loadedObjectInfo);

  },[updatedShareObjectInfo]);

  useEffect(() => {
    console.log('updatedObjectInfo loadedObjectInfo', updatedShareObjectInfo, loadedObjectInfo);
    if (updatedObjectInfo) {
      objectUpdateHandler(updatedObjectInfo, loadedObjectInfo);
    }

  },[updatedObjectInfo]);

  useEffect(() => {
      // const socket = openSocket(BACKEND_URL);
      const socket = io(BACKEND_URL, {
        // autoConnect: false
      });

      const userId = localStorage.getItem('userId');

      socket.on("connect", () => {
        console.log('connect socket.id', socket.id);
        // dispatch('SET_SOCKETID', socket.id);

        socket.emit('user-id', {
          socketId: socket.id,
          userId: userId,
        });
      });


      //// file info update (not content) by owner case
      socket.on('tools-file-info-updated-for-owner', data => {
        console.log('tools-file-info-updated-for-owner', data);
        //// when not updated user, update by using token

        // dispatch('SET_UPDATEDSHAREOBJECTINFO', data);
        setUpdatedObjectInfo(data);
      });

      socket.on('tools-updated-object-data', data => {
        console.log('tools-updated-object-data data', data);
        //// when not updated user, update by using token

        dispatch('SET_UPDATEDSHAREOBJECTINFO', data);
      });

      socket.on('tools-updated-object-data-by-share-user', data => {
        console.log('tools-updated-object-data-by-share-user data', data);
        //// when not updated user, update by using token

        dispatch('SET_UPDATEDSHAREOBJECTINFO', data);
      });

  },[]);


  const shareObjectUpdateHandler = async (updatedShareObjectInfo, loadedObjectInfo) => {
    try {
      if (updatedShareObjectInfo && loadedObjectInfo) {
        const data = updatedShareObjectInfo;
  
        const updatedToken = data.token;
        const decodedToken = getLoadObjectInfo(data.token);
  
        //// when user opening file is updated share file,  and updated by different user, load updated file.
        //// in same user's case use return data to user request for update.
        if (updatedShareObjectInfo.oldKey === loadedObjectInfo.key && 
            updatedShareObjectInfo.userId !== loadedObjectInfo.userId
        ) {
  
          dispatch('SET_LOADEDOBJECTINFO', decodedToken);
          dispatch('SET_LOADEDTOKEN', updatedToken);
  
          const loadResult = await updateLoadDataHandler(
            BACKEND_URL,
            updatedToken,
            data.bucket,
            data.key,
            data.mimeType,
          );

          console.log(loadResult);
        }
      }
    } catch(err) {
      console.log(err);
      throw err;
    }
  };

  //// for file info update (not content) by owner case
  const objectUpdateHandler = async (updatedObjectInfo, loadedObjectInfo) => {
    try {
      if (updatedObjectInfo && loadedObjectInfo) {
        const data = updatedObjectInfo;
  
        const updatedToken = data.token;
        const decodedToken = getLoadObjectInfo(data.token);
  
        //// when user opening file is updated share file,  and updated by different user, load updated file.
        //// in same user's case use return data to user request for update.
        if (updatedObjectInfo.oldKey === loadedObjectInfo.key && 
            updatedObjectInfo.bucket === loadedObjectInfo.userId
        ) {
  
          dispatch('SET_LOADEDOBJECTINFO', decodedToken);
          dispatch('SET_LOADEDTOKEN', updatedToken);
  
          const loadResult = await updateLoadDataHandler(
            BACKEND_URL,
            updatedToken,
            data.bucket,
            data.key,
            data.mimeType,
          );

          console.log(loadResult);
        }
      }
    } catch(err) {
      console.log(err);
      throw err;
    }
  };


  const updateLoadDataHandler = async (url, token, bucket, key, mimeType) => {
    try {
      // setIsLoading(true);

      // console.log('getLoadDataHandler')
      const resBlob = await getLoadData(url, token, bucket, key, mimeType);
      
      console.log(resBlob);

      if (mimeType && mimeType.split('/') === 'image') {
        const imgEl = document.getElementById('img');
        var objectURL = URL.createObjectURL(resBlob);
        imgEl.src = objectURL;
      }


      const textData = await resBlob.text();
      console.log(textData);

      if (textData.startsWith('<')) {
        dispatch('SET_LOADEDDATA', textData);
        // navigate('/text-editor');
        return resBlob;
      }

      if (textData.startsWith('{"id"') && 
          textData.includes(',"type":"PRESENTATION",')
        ) {
          dispatch('SET_LOADEDDATA', textData);
          // navigate('/slide');
          return resBlob;
      }

      if (textData.startsWith('{')) {
        dispatch('SET_LOADEDDATA', textData);
        // navigate('/spreadsheet');
        return resBlob;
      }


      if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        setWordFileHandler(resBlob);
        return resBlob;
      }

      if (mimeType === 'application/vnd.oasis.opendocument.text') {
        setOdtFileHandler(
          BACKEND_URL,
          token,
          resBlob,
          bucket,
        );
        return resBlob;
      }

      else {
        sheetBlobLoadHandler(resBlob, mimeType);
        // dispatch('SET_LOADEDDATA', file);
        // navigate('/spreadsheet');
        return resBlob;
      }

      // setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  };
  return (
    <Fragment></Fragment>
  );
}

export default FileUpdateSocket;