import React, {Fragment} from "react";
import { useTranslation } from 'react-i18next';

import {
  // ContextMenu,
  // MenuItem,
  // ContextMenuTrigger,
  // SubMenu,
  showMenu,
  hideMenu,
} from "react-contextmenu";

// import ContextMenu1 from "./ContextMenu1";
// import ContextMenu2 from "./ContextMeun2";
import EditorFileMenu from "./EditorFileMenu";
import EditorAboutKuraStorage from "../QuillEditor/EditorAboutKuraStorage";

import { useStore } from "../../../../hook-store/store";

import "./EditorFileControl.css";
import "./react-contextmenu.css";

const menuId1 = 'menuId-1';
const menuId2 = 'menuId-2';

const tText = '-title';

const menuIdList = [menuId1, menuId2];

// const MENU_TYPE = "SIMPLE";
// const menuName = 'menu-name';
// const menuName2 = 'menu-name2';
// const menuId1Title = 'menuId-1-title';
// const menuId2Title = 'menuId-2-title';

// const menuItem1 = 'menu-item1';
// const menuItem2 = 'menu-item2';
// const menuItem3 = 'menu-item3';

function EditorFileControl(props) {
  const { 
    editorRef,
    rightDisable,
   } = props;

   const [t] = useTranslation('translation');

   const [store, dispatch] = useStore();

   const { 
    showAboutKuraStorage,
    textUpdateTime,
   } = store.textEditorStore;

  let disable = false;
  
  if (rightDisable) {
    disable = true;
  }

  function handleClick(e, data) {
    console.log(data);
  }

  function handleOnShow(e) {
    // console.log(e);
  }

  function handleOnHide(e) {
    // console.log(e);

    // const contextMenuEls = document.getElementsByClassName('react-contextmenu');
    // console.log(contextMenuEls);
  }

  function handleLeftMouseClick(e, id, idTitle) {
    const menuNameEl = document.getElementById(idTitle);
    // console.log(e, menuNameEl);
    
    const openedMenuEls = document.getElementsByClassName('react-contextmenu--visible');
    // console.log(openedMenuEls);

    //// hide menues when opened
    if (openedMenuEls && openedMenuEls.length > 0) {
      for (const mId of menuIdList) {
          hideMenu({ id: mId });
      }

      return;
    }
    

    if (menuNameEl) {
      const rect = menuNameEl.getBoundingClientRect();
      // console.log(rect);
      // console.log(e);

      // const x = e.clientX;
      // const y = e.clientY;
      const rx = rect.left + 5;
      const ry = rect.top + rect.height;
  
      showMenu({
        // position: { x, y },
        position: { x: rx, y: ry },
        id: id,
      });

      for (const mId of menuIdList) {
        if (mId !== id) {
          hideMenu({ id: mId });
        }
      }

    }
  }


  return (
    <Fragment>
      <div className="editorFileControl">
        <div>
          <EditorFileMenu 
            menuId={menuId2}
            tText={tText}
            handleLeftMouseClick={handleLeftMouseClick}
            disable={disable}
            editorRef={editorRef}
          />
        </div>

        {textUpdateTime > 0 && (
          <span className='editorFileControlFileUpdateTime'>
            ({t('textEditorFile.02', 'last update')}: {new Date(textUpdateTime).toLocaleTimeString()})
          </span>
        )}

          {showAboutKuraStorage && (
            <EditorAboutKuraStorage />
          )}
      </div>
    </Fragment>
  );
}

export default EditorFileControl;
