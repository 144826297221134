import { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import _, { result } from 'lodash';
// import axios from 'axios';

// // import Backdrop from '../../Backdrop/Backdrop';
// // import BucketObjectAllList from './BucketObjectAllList';
// import BucketObjectList from './BucketObjectList';
// // import Loader from '../../Loader/Loader';
// import ObjectControl from '../ObjectControl/ObjectControl';

import { useStore } from '../../../hook-store/store';

import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import * as fileMoveUtils from '../../../utils/bucket/file-move-util';
import * as objectAccessUtils from '../../../utils/bucket/object-access-util';

import { BACKEND_URL } from '../../../App';




// import ObjectAccess from '../ObjectAccess/ObjectAccess';
// import { marks } from '../../../utils/marks';

// import ObjectSearchHandle from './ObjectSearchHandle';


function GetAllObjects(props) {

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefix = store.bucketObjectStore.movePrefix;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;
  const freeSizeGB = store.bucketObjectStore.freeSizeGB;

  const allBucketObjects = store.bucketObjectStore.allBucketObjects;
  const searchSelectedItem = store.bucketObjectStore.searchSelectedItem;
  const objectAction = store.bucketObjectStore.objectAction;

  const displayTable = store.bucketObjectStore.displayTable;

  // const { subscriptions } = store.stripeData;

  const gLoading = store.uiStore.gLoading;

 
  useEffect(() => {
    if (bucketName && allBucketObjects.length === 0) {
      getAllObjects(bucketName, '');
    }
  },[bucketName]);

  
  // useEffect(() => {
  //   const activeSubscription = subscriptions.find(subscription => {
  //     return subscription.status === 'active';
  //   });
  
  //   if (activeSubscription) {
  //     const subLimitGB = Number(activeSubscription.metadata.storeSizeGB);
      
  //     if (subLimitGB > 0) {
  //       dispatch('SET_LIMITGB', subLimitGB);
  //     }

  //   } else {
  //     dispatch('SET_LIMITGB', freeSizeGB);
  //   }

  // },[subscriptions]);

  // useEffect(() => {
  //   if (allBucketObjects.length > 0) {
  //     createObjectsList(allBucketObjects);
  //   }
  // },[allBucketObjects]);


  const getAllObjects = async (bucket, prefix) => {
    try {
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const resData = await bucketObjectUtils.getAllBucketObjects(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        prefix,
      );
      // const result = await fetch(BACKEND_URL + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`);
      console.log(resData);

      // dispatch('SET_CURRENT_PREFIX', prefix);
      
      dispatch('SET_ALLBUCKETOBJECTS', resData.data.objectList);

      createObjectsList(resData.data.objectList);

      // setBucketObjects(resData.data.objectList);

      // setCurrentPrefixHandler(resData.data.params.Prefix);

      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  const createObjectsList = (allObjects) => {
    const prefixList = [];
    const fileList = [];
    let totalSize = 0;

    for (const object of allObjects) {
      if (object.Key.endsWith('/')) {
        // prefixList.push({
        //   ...object,
        //   Prefix: object.Key 
        // });
        prefixList.push(object);
        // totalSize = totalSize + object.Size;
      } 
      else {
        fileList.push(object);
        totalSize = totalSize + (object.Size / 10**9);
      }
    }
    // console.log('totalSize', totalSize);

    dispatch('SET_ALLFOLDERLIST', prefixList);
    dispatch('SET_ALLFILELIST', fileList);
    dispatch('SET_BUCKETSIZEGB', totalSize);

  };



  
  return (
    <Fragment>
    </Fragment>
  );
}

export default GetAllObjects;
