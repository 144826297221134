// const React = window.React = require('react');
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const HeadMetaTag = (props) => {

  const [t] = useTranslation('translation');

  const title = `Kura Storage Tools - ${t('headMeta01', 'simple tools for your files')}`;

  const description = `Kura Storage Tools by SpaceEight - Kura Storage Tools ${t('headMeta02', 'provide simple text editor, spereadsheet, word file or excel file import and export, pdf download')}.`
  + ' ' 
  + `${t('headMeta03', 'It has a simple tool to create slide presentation as well')}`;

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </Fragment>
  );
}

export default HeadMetaTag;