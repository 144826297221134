import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_LOADEDDATA: (state, payload) => {
      return { 
        // ...state,
        toolsStore : {
          ...state.toolsStore,
          loadedData: payload, 
        }
      };
    },
    SET_LOADEDTOKEN: (state, payload) => {
      return { 
        // ...state,
        toolsStore : {
          ...state.toolsStore,
          loadedToken: payload, 
        }
      };
    },
    SET_LOADEDOBJECTINFO: (state, payload) => {
      return { 
        // ...state,
        toolsStore : {
          ...state.toolsStore,
          loadedObjectInfo: payload, 
        }
      };
    },
    SET_UPDATEDSHAREOBJECTINFO: (state, payload) => {
      return { 
        // ...state,
        toolsStore : {
          ...state.toolsStore,
          updatedShareObjectInfo: payload, 
        }
      };
    },
 

  };

  initStore(actions, {
    toolsStore: {
      loadedData: null,
      loadedToken: '',
      loadedObjectInfo: null,
      updatedShareObjectInfo: null,
      // serviceBucketsInfo: null,
    }
  });
};

export default configureStore;
