import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger,
  // showMenu,
  // SubMenu,
  // hideMenu,
} from "react-contextmenu";

import ExportExcelButton from './ExportExcel/ExportExcelButton';
import ImportExcel from './ImportExcel/ImportExcel';
import SheetFileUpdate from './SheetFileUpdate/SheetFileUpdate';

import { useStore } from '../../../../hook-store/store';
import { getOriginalFileNameFromKey } from '../../../../utils/bucket/bucket-object-util';
// import { getLoadObjectInfo } from '../../../../utils/tools-bucket-object/tools-bucket-object-util';

import { mimeTypes } from '../../../../utils/file/mime-type';

import { BACKEND_URL } from '../../../../App';
// import './App.css';



function SheetFileMenu(props) {
  const {     
    menuId, 
    tText,
    handleLeftMouseClick,
    disable, 
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { loadedObjectInfo } = store.toolsStore;
  const { isAuth } = store.uiStore;

  // const [sheetTitle, setSheetTitle] = useState('');
  // const [sheetTitleInput, SetSheetTitleInput] = useState('');

  // const [isLoading, setIsLoading] = useState(false);

  function handleOnShow(e) {
    // console.log(e);
  }

  function handleOnHide(e) {
    // console.log(e);

    // const contextMenuEls = document.getElementsByClassName('react-contextmenu');
    // console.log(contextMenuEls);
  }

  let dispFileName;
  
  if (loadedObjectInfo && loadedObjectInfo.key) {
    dispFileName = getOriginalFileNameFromKey(loadedObjectInfo.key);
  }

  return (
    <Fragment>
      <div className="sheetFileMenu">
        <ContextMenuTrigger id={menuId} 
          holdToDisplay={1000}
          // holdToDisplay={0}
          disable={disable}
        >
          <span id={menuId + tText} className="well editorFileMenuTitle"
            onClick={(e) => { 
              handleLeftMouseClick(e, menuId, menuId + tText)
            }}
          >
              {t('sheet01', 'File menu')}
          </span>
        </ContextMenuTrigger>
      </div>

      <ContextMenu 
        id={menuId}
        onShow={handleOnShow}
        onHide={handleOnHide}
        // hideOnLeave={true}
      >
        <div className='react-contextmenu-item'
          style={{ cursor: 'default', paddingBottom:"20px" }}
        >
          {dispFileName}
        </div>
        {!isAuth && (
          <MenuItem
            // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
          >
            <ImportExcel />
          </MenuItem>
        )}

        {isAuth && (
          <MenuItem
            // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
          >
            <SheetFileUpdate />
          </MenuItem>
        )}
        
        <MenuItem
          // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
        >
          <ExportExcelButton />
        </MenuItem>
      </ContextMenu>

    </Fragment>
  );
}

export default SheetFileMenu;
