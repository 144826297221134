import React, {Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import mammoth, { read } from 'mammoth/mammoth.browser';
import * as quillToWord from "quill-to-word";
import { saveAs } from 'file-saver';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';


import { useStore } from '../../../../../hook-store/store';
import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';
import { 
  convertOdtToHtml, 
  convertHtmlFileToDocx,
} from '../../../../../utils/tools-bucket-object/tools-file-convert-util';
import {
  inlineStyles
} from '../../../../../utils/tools-bucket-object/quill-file-convert-util';
import { mimeTypes } from '../../../../../utils/file/mime-type';


import { BACKEND_URL } from '../../../../../App';

// import { marks } from '../../../../utils/marks';
// import './WordFileControl.css';
import '../EditorFileMenu.css';


function WordFileControl(props) {
  const { 
    editorRef,
    // editorHtml,
    // setEditorHtml
   } = props;

  const [t] = useTranslation('translation');

  const wordImportRef = useRef(null);
  const odtImportRef = useRef(null);

  const [store, dispatch] = useStore();
  const { isAuth, gLoading } = store.uiStore;
  // const { loadedData } = store.toolsStore;
  const { editorHtml } = store.textEditorStore;
  const { loadedObjectInfo, loadedToken } = store.toolsStore;
  // const [isLoading, setIsLoading] = useState(false);




  const wordDownloadHandler = async (fimeName) => {
    try {} catch(err) {
      console.log(err);
    }
    const quillInstance = editorRef.current.getEditor();
    const quillDelta = quillInstance.getContents();
    console.log(quillDelta);

    const quillToWordConfig = {
      exportAs: 'blob',

      paragraphStyles: {
        normal: {
          paragraph: {
              spacing: {
              }
          },
          run: {
              font: 'sans-serif',
          }
        },
        // header_1: {
        //   paragraph: {
        //       spacing: {}
        //   },
        //   run: {
        //     size: 48,
        //     font: 'sans-serif',
        //   }
        // },
        // header_1: {
        //   paragraph: {
        //       spacing: {
        //           before: 1200,
        //           after: 1000
        //       }
        //   },
        //   run: {
        //       size: 64,
        //       bold: true,
        //       color: 'ff88bb'
        //   }
        // },
      }
    };

    // const docAsBlob = await quillToWord.generateWord(quillInstance.getContents());
    const docAsBlob = await quillToWord.generateWord(quillDelta, quillToWordConfig);
    
    console.log(docAsBlob);
    saveAs(docAsBlob, 'word-export.docx');
    // const doc = await quillToWord.generateWord(quillDelta);

    // console.log(doc);
    // download(doc, 'word-download.docx')

  };


  const htmlToDocxHandler = async (url, token, bucket, key) => {
    try {
      dispatch('SET_GLOADING', true);
      // setIsLoading(true);

      const quillInstance = editorRef.current.getEditor();
      const quillDelta = quillInstance.getContents();
      console.log(quillDelta);
  
     const cfg = {
      inlineStyles: inlineStyles,
      // inlineStyles: true,
     };
      
      // var converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
      const converter = new QuillDeltaToHtmlConverter(quillDelta.ops, cfg);
      
      var html = converter.convert(); 
      // console.log('html', html);
  
      // html = `<!DOCTYPE html><html style="font-family:sans-serif"><head></head><body>` + 
      //   html + 
      //   `</body></html>`

      html = `<!DOCTYPE html><html><head></head><body style="font-family:sans-serif">` + 
        html + 
        `</body></html>`
  
      // console.log(html);

      // throw new Error('error-error');
  
      const fileHtml = createTextFile(html, 'quill-html.html');
      
      const result = await convertHtmlFileToDocx(
        url,
        token,
        [fileHtml],
        bucket,
        key,
      );

      console.log(result);
      // console.log(fileHtml);
  
      const blob = new Blob([new Uint8Array(result.data).buffer], { type: mimeTypes.docx })
      saveAs(blob, 'convert-word.docx');
      
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'download failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }

  };

  // const convertOdtToHtmlHandler = async (event) => {
  //   try {
  //     const files = event.target.files;
  //     // const file = new File([blobData], "name");
    
  //     const odtHtml = await convertOdtToHtml(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       files,
  //       localStorage.getItem('userId'),
  //     );

  //     // console.log(odtHtml);
  //     dispatch('SET_EDITORHTML', odtHtml);
  //     // const files = event.target.files;


  //   } catch(err) {
  //     console.log(err);
  //     throw err;
  //   }
  // };

  // const onWordImportClick = () => {
  //   wordImportRef.current.click(); 
  // };

  // const onOdtImportClick = () => {
  //   odtImportRef.current.click(); 
  // };


  return (
    <Fragment>
          {!isAuth && (
            <button className='editorFileMeunButton'
              disabled={!editorHtml || editorHtml === "<p><br></p>"}
              onClick={wordDownloadHandler}
            >
              {t('textEditorFile.09', 'download as word file')}
            </button>
          )}

          {isAuth && (
            <button className='editorFileMeunButton'
              disabled={!editorHtml || editorHtml === "<p><br></p>" || gLoading}
              onClick={() => {
                htmlToDocxHandler(
                  BACKEND_URL,
                  loadedToken,
                  loadedObjectInfo.bucket,
                  loadedObjectInfo.key,
                );
              }}
            >
              {t('textEditorFile.09', 'download as word file')}
            </button>
          )}

        {/* </div> */}
    </Fragment>
    )
}


export default WordFileControl;