import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import mammoth, { read } from 'mammoth/mammoth.browser';
import LuckyExcel from 'luckyexcel'

import Loader from "../Loader/Loader";

import { useStore } from "../../hook-store/store";

import { getLoadObjectInfo, getLoadData } from "../../utils/tools-bucket-object/tools-bucket-object-util";
import { convertOdtToHtml } from "../../utils/tools-bucket-object/tools-file-convert-util";
import { mimeTypes } from "../../utils/file/mime-type";

import { BACKEND_URL } from "../../App";

const LoadFile = (props) => {
  const { } = props;

  const navigate = useNavigate();

  // const [authCheckStart, setAuthCheckStart] = useState(false);

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  // const { loadedToken } = store.toolsStore;

  // const [fileUrl, setFileUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuth) {
      const loadedData = getLoadObjectInfo(localStorage.getItem('token'));
      console.log('loadedData', loadedData);

      dispatch('SET_LOADEDOBJECTINFO', loadedData);
      dispatch('SET_LOADEDTOKEN', localStorage.getItem('token'));

      getLoadDataHandler(
        BACKEND_URL,
        localStorage.getItem('token'),
        loadedData.bucket,
        loadedData.key,
        loadedData.mimeType,
      );
   
      // fetchLoadData2(loadedData.fileUrl);

    }
  },[isAuth]);



  const getLoadDataHandler = async (url, token, bucket, key, mimeType) => {
    try {
      setIsLoading(true);

      console.log('getLoadDataHandler')
      const resBlob = await getLoadData(url, token, bucket, key, mimeType);
      
      console.log(resBlob);

      if (mimeType && mimeType.split('/') === 'image') {
        const imgEl = document.getElementById('img');
        var objectURL = URL.createObjectURL(resBlob);
        imgEl.src = objectURL;
      }


      const textData = await resBlob.text();
      console.log(textData);

      if (textData.startsWith('<')) {
        dispatch('SET_LOADEDDATA', textData);
        navigate('/text-editor');
        return;
      }

      if (textData.startsWith('{"id"') && 
          textData.includes(',"type":"PRESENTATION",')
        ) {
          dispatch('SET_LOADEDDATA', textData);
          navigate('/slide');
          return;
      }

      if (textData.startsWith('{')) {
        dispatch('SET_LOADEDDATA', textData);
        navigate('/spreadsheet');
        return;
      }


      if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        setWordFileHandler(resBlob);
      }

      if (mimeType === 'application/vnd.oasis.opendocument.text') {
        setOdtFileHandler(
          BACKEND_URL,
          token,
          resBlob,
          bucket,
        );
      }

      else {
        sheetBlobLoadHandler(resBlob, mimeType);
        // dispatch('SET_LOADEDDATA', file);
        // navigate('/spreadsheet');
        return;
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  };


  const setWordFileHandler = (blobData) => {
    var file = new File([blobData], "name");
    // console.log(file);

    const reader = new FileReader();
    const fileByteArray = [];

    reader.readAsArrayBuffer(file);

    reader.onloadend = async function (evt) {
      try {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);

          for (var i = 0; i < array.length; i++) {
              fileByteArray.push(array[i]);
            }

          console.log(arrayBuffer);

          const result = await mammoth.convertToHtml({arrayBuffer: arrayBuffer})
          var html = result.value; // The generated HTML
          var messages = result.messages; // Any messages, such as warnings during conversion
          // console.log(messages, html);

          // setEditorHtml(html);
          dispatch('SET_EDITORHTML', html);
          navigate('/text-editor');

        }
      } catch(err) {
        console.log(err);
        throw err;
      }
    
    }
  };

  const setOdtFileHandler = async (url, token, blobData, bucket) => {
    try {
      const file = new File([blobData], "name");

      const odtHtml = await convertOdtToHtml(
        url,
        token,
        [file],
        bucket,
      );

      // console.log(odtHtml);
      dispatch('SET_EDITORHTML', odtHtml);
      navigate('/text-editor');

    } catch(err) {
      console.log(err);
      throw err;
    }
  };


  const sheetBlobLoadHandler = (blob, mimeType) => {

    let file;

    if (mimeType === mimeTypes.xlsx) {
      file = new File([blob], 'filename', { type: mimeTypes.xlsx });
    }


    // After getting the xlsx file
    LuckyExcel.transformExcelToLucky(file, 
      function(exportJson, luckysheetfile){
          // Get the worksheet data after conversion
          // console.log(exportJson, luckysheetfile);

          const forLoad = {
            title: 'sheet-title',
            data: exportJson.sheets,
          };

          console.log('forLoad',forLoad);
          dispatch('SET_LOADEDDATA', JSON.stringify(forLoad));
          navigate('/spreadsheet');
      },
      function(error){
          // handle error if any thrown
          console.log(error);
      }
    )
  }








  return <Fragment>
    {/* load-file-component */}
    {isLoading && <Loader />}
    <img id="img" />
    </Fragment>;
};

export default LoadFile;
