"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticImageObject = void 0;
// @ts-nocheck
var fabric_1 = require("fabric");
var StaticImageObject = /** @class */ (function (_super) {
    __extends(StaticImageObject, _super);
    function StaticImageObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.role = "regular";
        return _this;
    }
    //@ts-ignore
    StaticImageObject.prototype.initialize = function (element, options) {
        this.role = element.role;
        options.type = "StaticImage";
        //@ts-ignore
        _super.prototype.initialize.call(this, element, options);
        return this;
    };
    StaticImageObject.fromObject = function (options, callback) {
        fabric_1.fabric.util.loadImage(options.src, function (img) {
            // @ts-ignore
            return callback && callback(new fabric_1.fabric.StaticImage(img, options));
        }, null, 
        // @ts-ignore
        { crossOrigin: "anonymous" });
    };
    StaticImageObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    StaticImageObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    StaticImageObject.type = "StaticImage";
    return StaticImageObject;
}(fabric_1.fabric.Image));
exports.StaticImageObject = StaticImageObject;
fabric_1.fabric.StaticImage = fabric_1.fabric.util.createClass(StaticImageObject, {
    type: StaticImageObject.type,
});
fabric_1.fabric.StaticImage.fromObject = StaticImageObject.fromObject;
