// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sfN6NE_6j853QF49_5VY {\n  padding: 5rem 0.5rem 2rem 0.5rem;\n}\n\n.qk0Jd8wqYAEvJKCtU1yo {\n  padding-bottom: 1rem;\n  font-size: 1.1rem;\n}\n\n.aVnFwWO8vuEydHzd5Obo {\n  text-align: center;\n  font-size: 1.5rem;\n  padding-bottom: 1rem;\n  font-weight: bold;\n}\n\n.gAN4FmouIrZHCLY_eQcE {\n  font-size: 1.25rem;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/pages/SizeManagePage/SizeManagePage.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".sizeManage {\n  padding: 5rem 0.5rem 2rem 0.5rem;\n}\n\n.sizeManageInfo {\n  padding-bottom: 1rem;\n  font-size: 1.1rem;\n}\n\n.sizeManagePlanTitle {\n  text-align: center;\n  font-size: 1.5rem;\n  padding-bottom: 1rem;\n  font-weight: bold;\n}\n\n.sizeManageLink {\n  font-size: 1.25rem;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sizeManage": "sfN6NE_6j853QF49_5VY",
	"sizeManageInfo": "qk0Jd8wqYAEvJKCtU1yo",
	"sizeManagePlanTitle": "aVnFwWO8vuEydHzd5Obo",
	"sizeManageLink": "gAN4FmouIrZHCLY_eQcE"
};
export default ___CSS_LOADER_EXPORT___;
