"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundImageObject = void 0;
// @ts-nocheck
var fabric_1 = require("fabric");
var BackgroundImageObject = /** @class */ (function (_super) {
    __extends(BackgroundImageObject, _super);
    function BackgroundImageObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    //@ts-ignore
    BackgroundImageObject.prototype.initialize = function (element, options) {
        var _this = this;
        options.type = "BackgroundImage";
        //@ts-ignore
        _super.prototype.initialize.call(this, element, __assign(__assign({}, options), { hasControls: false, lockMovementY: true, lockMovementX: true, selectable: false, hoverCursor: "default", hasBorders: false }));
        this.on("mouseup", function (_a) {
            var target = _a.target;
            var activeSelection = _this.canvas.getActiveObject();
            if (!activeSelection && target === _this) {
                _this.canvas.fire("background:selected");
            }
        });
        this.on("mousedblclick", function () {
            _this.set({
                hasControls: true,
                lockMovementY: false,
                lockMovementX: false,
                hasBorders: true,
            });
            _this.canvas.setActiveObject(_this);
            _this.canvas.requestRenderAll();
        });
        return this;
    };
    BackgroundImageObject.fromObject = function (options, callback) {
        fabric_1.fabric.util.loadImage(options.src, function (img) {
            // @ts-ignore
            return callback && callback(new fabric_1.fabric.BackgroundImage(img, options));
        }, null, 
        // @ts-ignore
        { crossOrigin: "anonymous" });
    };
    BackgroundImageObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    BackgroundImageObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    BackgroundImageObject.type = "BackgroundImage";
    return BackgroundImageObject;
}(fabric_1.fabric.Image));
exports.BackgroundImageObject = BackgroundImageObject;
fabric_1.fabric.BackgroundImage = fabric_1.fabric.util.createClass(BackgroundImageObject, {
    type: BackgroundImageObject.type,
});
fabric_1.fabric.BackgroundImage.fromObject = BackgroundImageObject.fromObject;
