import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, KIND } from "baseui/button"
import { useEditor } from "@layerhub-io/react"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"

import SlideAboutKuraStorage from "./SlideAboutKuraStorage";

import { useStore } from '../../../../../hook-store/store';
import { s3fileUpdate } from '../../../../../utils/bucket/bucket-upload-util';
import { 
  getLoadObjectInfo, 
  setUpdatedToken,
  getLoadData,
} from '../../../../../utils/tools-bucket-object/tools-bucket-object-util';
import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';

// import { exportExcel } from './ExportExcel/exportExcel';
// import { mimeTypes } from '../../../../../utils/file/mime-type';

import { BACKEND_URL } from '../../../../../App';

import { marks } from '../../../../../utils/marks';
// import './App.css';
// import '../../../TextEditor/FileControl/EditorFileMenu.css';


function SlideFileUpdate(props) {

  const { setDisplayPreview, setScenes, setCurrentDesign, currentDesign, scenes } = useDesignEditorContext()
  const editor = useEditor();

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  const { showSlideAboutKuraStorage } = store.presentationStore;
  const { loadedObjectInfo, loadedToken } = store.toolsStore;

  const [isLoading, setIsLoading] = useState(false);

  let canUpdate = false;
  
  if ((loadedObjectInfo && loadedObjectInfo.shareType === 'write') ||
       loadedObjectInfo && loadedObjectInfo.bucket === loadedObjectInfo.userId
  ) {
      canUpdate = true;
  }


  const parsePresentationJSONData = () => {
    const currentScene = editor.scene.exportToJSON()

    const updatedScenes = scenes.map((scn) => {
      if (scn.id === currentScene.id) {
        return {
          // id: currentScene.id,
          // duration: 5000,
          layers: currentScene.layers,
          // name: currentScene.name,
        }
      }
      return {
        // id: scn.id,
        // duration: 5000,
        layers: scn.layers,
        // name: scn.name,
      }
    })

    if (currentDesign) {
      const presentationTemplate = {
        id: currentDesign.id,
        type: "PRESENTATION",
        name: currentDesign.name,
        frame: currentDesign.frame,
        scenes: updatedScenes,
        metadata: {
          ...currentDesign.metadata,
        },
        preview: "",
      }

      // console.log(presentationTemplate)
      // const dataString = JSON.stringify(presentationTemplate);
      // return dataString;

      return presentationTemplate

      // const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(presentationTemplate))}`
      // return dataStr
      // makeDownload(presentationTemplate)
    } else {
      console.log("NO CURRENT DESIGN")
    }
  }


  // const makeDownload = (data: Object) => {
  //   const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`
  //   const a = document.createElement("a")
  //   a.href = dataStr
  //   a.download = "slide-data.txt"
  //   a.click()
  // }



  const dataUpdateHandler = async() => {
    try {

      dispatch('SET_GLOADING', true);
      setIsLoading(true);

      const slideData = parsePresentationJSONData();
      // const sheetData = window.luckysheet.toJson();
      // const namedData = {
      //   ...sheetData,
      //   title: sheetTitleInput,
      // };

      // console.log(slideData);

      // throw new Error('error-error');


      const loadedData = loadedObjectInfo;
      const key = loadedData.key;

      let path = '';
      const keySplitList = key.split('/');
      const fileName = key.split('/')[key.split('/').length -1];
      const timeEndIndex = fileName.indexOf('Z');
      const originalName = fileName.substring(timeEndIndex+2);

      if (keySplitList.length > 1) {
        keySplitList.pop();
        path = keySplitList.join('/') + '/';
      }

      const file = createTextFile(JSON.stringify(slideData), originalName);

      // console.log(file);

      // console.log(fileName, originalName, keySplitList);

      // download(file, `lsheet-data.txt`, 'text/plain');
      // return;

      const result = await s3fileUpdate(
        BACKEND_URL,
        loadedToken,
        path,
        key,
        [file],
        loadedData.bucket,
        dispatch,
      );
      
      if (result.data && result.data.data && result.data.data[0]) {

        console.log(result, result.data.data[0]);
        const updatedToken = result.data.data[0].token;
  
        setUpdatedToken(updatedToken);
        // dispatch('SET_LOADEDTOKEN', updatedToken);
        
        const decodedToken = getLoadObjectInfo(updatedToken);
        
        dispatch('SET_LOADEDOBJECTINFO', decodedToken);
        dispatch('SET_LOADEDTOKEN', updatedToken);

        console.log(decodedToken);
  
        await updateLoadDataHandler(
          BACKEND_URL,
          updatedToken,
          decodedToken.bucket,
          decodedToken.key,
          decodedToken.mimeType,
        );

        dispatch('SET_SLIDEUPDATETIME', Date.now());

      }

      dispatch('SET_GLOADING', false);
      setIsLoading(false);

    } catch(err) {
      console.log(err);

      dispatch('SET_GLOADING', false);
      setIsLoading(false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'update file failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };


  const updateLoadDataHandler = async (url, token, bucket, key, mimeType) => {
    try {
      const resData = await getLoadData(url, token, bucket, key, mimeType);

      if (resData.data) {
        dispatch('SET_LOADEDDATA', resData.data);

        // if (resData.data.startsWith('{')) {
        //   navigate('/spreadsheet');
        // }

        // if (resData.data.startsWith('<')) {
        //   navigate('/text-editor');
        // }
      }



    } catch(err) {
      console.log(err);
      throw err;
    }
  };



  let slideFileUpdateBody;

  if (!isAuth) {
    slideFileUpdateBody = (
      <span>
        <Button
          size="compact"
          // onClick={dataUpdateHandler}
          onClick={() => {
            dispatch('SET_SHOWSLIDEABOUTKURASTORAGE', true);
          }}
          kind={KIND.tertiary}
          overrides={{
            StartEnhancer: {
              style: {
                marginRight: "4px",
              },
            },
          }}
        >
          {t('sheet05', 'save file')} {marks.infoCircleMark}
        </Button>

        {showSlideAboutKuraStorage && (
          <SlideAboutKuraStorage />
        )}
      </span>
    );
  }

  if (isAuth) {
    slideFileUpdateBody = (
      <Button
        size="compact"
        disabled={!isLoading || !canUpdate}
        onClick={dataUpdateHandler}
        kind={KIND.tertiary}
        overrides={{
          StartEnhancer: {
            style: {
              marginRight: "4px",
            },
          },
        }}
      >
        {t('sheet05', 'save file')}
      </Button>
    );
  }





  return (
    <Fragment>
      <span>{slideFileUpdateBody}</span>
      {/* <button  style={{color:"red"}} className='editorFileMeunButton'
        onClick={dataUpdateHandler}
      >
        {t('sheet05', 'save file')}
      </button> */}
    </Fragment>
  );
}

export default SlideFileUpdate;
