import React, {Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import mammoth, { read } from 'mammoth/mammoth.browser';
// import * as quillToWord from "quill-to-word";
// import { saveAs } from 'file-saver';
// import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';


import { useStore } from '../../../../../hook-store/store';
// import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';
// import { 
//   convertOdtToHtml, 
//   convertHtmlFileToDocx,
// } from '../../../../../utils/tools-bucket-object/tools-file-convert-util';
// import {
//   inlineStyles
// } from '../../../../../utils/tools-bucket-object/quill-file-convert-util';
// import { mimeTypes } from '../../../../../utils/file/mime-type';


import { BACKEND_URL } from '../../../../../App';

// import { marks } from '../../../../utils/marks';
// import './WordFileControl.css';
import '../EditorFileMenu.css';

function WordFileImport(props) {
  const { 
    editorRef,
    // editorHtml,
    // setEditorHtml
   } = props;

  const [t] = useTranslation('translation');

  const wordImportRef = useRef(null);
  const odtImportRef = useRef(null);

  const [store, dispatch] = useStore();
  const { isAuth, gLoading } = store.uiStore;
  // const { loadedData } = store.toolsStore;
  const { editorHtml } = store.textEditorStore;

  // const [isLoading, setIsLoading] = useState(false);




  const wordFileSelectHandler = async (event) => {
    try {
      const file = event.target.files[0];
      // console.log(file)

      const fileNames = file.name.split('.');
      const ext = fileNames.pop();

      if (ext === 'docx') {
        const reader = new FileReader();
        const fileByteArray = [];
  
        reader.readAsArrayBuffer(file);
  
        reader.onloadend = async function (evt) {
          try {
            if (evt.target.readyState === FileReader.DONE) {
              const arrayBuffer = evt.target.result;
              const array = new Uint8Array(arrayBuffer);
  
              for (var i = 0; i < array.length; i++) {
                  fileByteArray.push(array[i]);
                }
  
              console.log(arrayBuffer);
  
              const result = await mammoth.convertToHtml({arrayBuffer: arrayBuffer})
              var html = result.value; // The generated HTML
              // var messages = result.messages; // Any messages, such as warnings during conversion
              // console.log(messages, html);
  
              // setEditorHtml(html);
              dispatch('SET_EDITORHTML', html);
  
            }
          } catch(err) {
            console.log(err);
          }
  
        }
      }
    }
     catch(err) {
      console.log(err)
    }
  };


  const onWordImportClick = () => {
    wordImportRef.current.click(); 
  };




  return (
    <Fragment>
        {/* <div className='wordFileControlButtons'> */}
          {!isAuth && (
            <span>
              <input 
                type='file' id='file' 
                ref={wordImportRef} 
                style={{display: 'none'}}
                accept=".docx"
                onChange={wordFileSelectHandler}
              />
              <button className='editorFileMeunButton'
                disabled={isAuth}
                onClick={onWordImportClick}
              >
                {t('textEditorFile.08', 'import word file')}
              </button>
            </span>
          )}
        {/* </div> */}
    </Fragment>
    )
}


export default WordFileImport;