import React, { useState, useEffect } from 'react';
// import './App.css';
import { Redirect, useHistory, Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import Loader from '../../components/Loader/Loader';
import { useStore } from '../../hook-store/store';
import { fetchPrices } from '../../utils/stripe';
import { BACKEND_URL, SUBSCRIPTION_BACKEND_URL } from '../../App';

import classes from './Register.module.css';

const Register = (props) => {

  // const url = new URL(window.location.href);
  // const queryParams = url.searchParams;
  // const customerEmail = queryParams.get('customer-email');

  const customerEmail = localStorage.getItem('email');
  // console.log(customerEmail);
  
  const lsToken = localStorage.getItem('token');

  // const history = useHistory();
  const navigate = useNavigate();

  const [state, dispatch] = useStore();
  // console.log(state);
  const { i18n } = state;

  // const [email, setEmail] = useState('jenny.rosen@example.com');
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  //// get prices data and login or register stripe user
  useEffect(() => {
    if (customerEmail) {
      setIsLoading(true);

      retrieveEmailCustomerHandler(customerEmail, lsToken)
        .then(res => {
          // console.log(res);

          dispatch('SET_ISSTRIPEAPI', true);
        })
        .catch(err => {
          // console.log(err);
          if (err.status === 404) {
            // add customer
            // console.log('add cucstomer')
            registerUser(customerEmail, lsToken);
          }
          else {
            setError(
              <div>
                <div>
                  {i18next.t('subText38', 'Subscription service is not available now.')}
                </div>
                <div>
                  <Link to='/'>    
                    {i18next.t('subText12', 'Go Back')}
                  </Link>
                </div>
              </div>
            );
            setIsLoading(false);
          }
        });
    }
    else {
      setError(
        <div>
          <div>
            {i18next.t('subText39', 'Login is required to access subscription service.')}
          </div>
          <div>
            <Link to='/'>    
              {i18next.t('subText12', 'Go Back')}
            </Link>
          </div>
        </div>
      );
    }
  },[]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

      registerUser(email, lsToken);
  };

  const registerUser = (emailAddr, token) => {
    return new Promise( async (resolve, reject) => {
      const { customer, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/customer/create-customer', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailAddr,
        }),
      })
      .then(r => {
        return r.json()
      })
      // .then(resData => {
      //   console.log(resData);
      // })
      // .catch(err => {
      //   console.log(err);
      // });
  
      if (error) {
        // console.log(error);
        setIsLoading(false);
        reject(error);
      }
  
      if (customer) {
        // console.log(customer);
        localStorage.setItem('customerId', customer.id);
        localStorage.setItem('customerEmail', customer.email);
        setCustomer(customer);
        setIsLoading(false);
        resolve(customer)
      }
    });
    };


  const retrieveEmailCustomerHandler = async (emailAddr, token) => {
    return new Promise(async (resolve, reject) => {

      setIsLoading(true);
  
      const { customer, error } = await fetch(SUBSCRIPTION_BACKEND_URL + '/customer/retrieve-email-customer', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          email: emailAddr,
        }),
      })
      .then(r => {
        return r.json()
      })
      // .then(resData => {
      //   console.log(resData);
      // })
      // .catch(err => {
      //   console.log(err);
      // });
  
      // console.log(customer, error);
  
      if (customer) {
        localStorage.setItem('customerId', customer.id);
        localStorage.setItem('customerEmail', customer.email);
        setCustomer(customer);
        setIsLoading(false);
        resolve(customer);
      }
      if (error) {
        // console.log(error);
        setIsLoading(false);
        reject(error);
      }
    })

  };

  if(customer) {
    // console.log(customer);
    // return <Redirect to={{pathname: '/prices'}} />
    // return <Redirect to={{pathname: '/account'}} />
    // return <Redirect to={{pathname: '/subscription/account'}} />
    
    // history.push('/subscription/account');

    // navigate('/subscription/account');
  }

  return (
    <main>
      {isLoading && 
        <div className={classes.subscriptionLoader}><Loader /></div>
      }

      {error && error}
    </main>
  );
}

export default Register;
