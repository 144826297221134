import React from "react"
import { Block } from "baseui/block"
import useDesignEditorScenes from "~/hooks/useDesignEditorScenes"
import { Carousel } from "react-responsive-carousel"
import { useEditor } from "@layerhub-io/react"
import { IScene } from "@layerhub-io/types"
import Loading from "~/components/Loading"

import { useFrame } from "@layerhub-io/react"

// import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./carousel.css"

export default function () {
  const [slides, setSlides] = React.useState<{ id: string; preview: string; videos: any[] }[]>([])
  const scenes = useDesignEditorScenes()
  const editor = useEditor()
  const frame = useFrame();

  const [loading, setLoading] = React.useState(true)

  const loadSlides = React.useCallback(
    async (scenes: IScene[]) => {
      const slides = []

      
      for (const scene of scenes) {

        const slideVideos = [];
        // console.log(scene, scenes)
        
        const layers = scene.layers;

        for (const layer of layers) {
          if (layer.type === 'StaticVideo') {
            slideVideos.push(layer);
          }
        }

        const preview = (await editor.renderer.render(scene)) as string
        slides.push({
          id: scene.id,
          preview,
          videos: slideVideos,
        })
      }

      // console.log(slides)
      setSlides(slides)
      setLoading(false)
    },
    [editor]
  )

  React.useEffect(() => {
    if (scenes && editor) {
      const currentScene = editor.scene.exportToJSON()
      const updatedScenes = scenes.map((scene) => {
        if (scene.id === currentScene.id) {
          return currentScene
        }
        return scene
      })
      loadSlides(updatedScenes)
    }
  }, [editor, scenes])

  return (
    <Block
      $style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        position: "relative",
      }}
    >
      <Block 
        $style={{ 
          position: "absolute", 
          // maxWidth: "840px",
          maxWidth: "90%",  
        }}
      >
        
        {loading && (<Loading />)}

        {!loading && (
          <Carousel 
            showIndicators={false} 
            showThumbs={false} 
            useKeyboardArrows={true} 
            showStatus={false}
            autoFocus={true}
          >
            {slides.map((page, index) => {
              if (page.videos.length === 0) {
                return <img width={"auto"} height={"100%"} key={index} src={page.preview} />
              } else {
                return (
                  <div style={{position: "relative"}}>
                    <img width={"auto"} height={"100%"} key={index} src={page.preview} />
                      {page.videos.map(video => {
                        console.log(video, frame)
                        const left = video.left/frame.width*100 + "%";
                        const top = video.top/frame.height*100 + "%";
                        const width = video.width*video.scaleX/frame.width*100 + "%";
                        const height = video.height*video.scaleY/frame.height*100 + "%";
                        const transform = `rotate(${video.angle}deg)`;
                        return (                  
                          <video key={video.id}
                            style={{ 
                              position: "absolute", 
                              width: width,
                              height: height, 
                              top: top, 
                              left: left,
                              transform: transform,
                              transformOrigin: "top left",
                            }}
                            controls
                            src={video.src} 
                            />
                          )
                      })}
                  </div>
                )
              }
            })}
          </Carousel>
        )}
      </Block>
    </Block>
  )
}
