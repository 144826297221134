import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from "../../../Backdrop/Backdrop";
// import ModalConfirm from "../../../Modal/ModalConfirm";
import Modal from '../../../Modal/Modal';
import AboutKuraStorageContent from '../../AboutKuraStorageContent/AboutKuraStorageContent';

import { useStore } from "../../../../hook-store/store";

import { kuraStorageLink } from "../../../../App";

// import { marks } from "../../../../utils/marks";
import KuraStorageImage1 from '../../../../images/kura-storage-ad-image-1.png';
import KuraStorageImage2 from '../../../../images/kura-storage-ad-image-2.png';
import "./EditorAboutKuraStorage.css";

function EditorAboutKuraStorage(props) {
  // const {
  //   // editorRef,
  //   // editorHtml,
  //   // setEditorHtml
  // } = props;

  const [t] = useTranslation('translation');

  // const importRef1 = useRef(null);

  const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;

  return (
    <Fragment>
        <div>
          <Backdrop
            onCancel={() => {
              dispatch('SET_SHOWABOUTKURASTORAGE', false);
            }}
          />
          <Modal
            hideButtons={true}
            onClose={() => {
              dispatch('SET_SHOWABOUTKURASTORAGE', false);
            }}
          >
            <AboutKuraStorageContent />
            {/* <div className="editorAboutKuraStorageModal">
              <p>{t('textEditorFile.03', 'If you need to store file, You can use')}
                {' '}
                <a
                  href={kuraStorageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kura Storage.
                </a>
                </p>
              <p>
                {t('textEditorFile.04', 'Kura Storage is simple storage place for your files. You can upload, store, access, and download files.')}
              </p>
              <p>
                {t('textEditorFile.05', 'There is free storage space to upload files. It is possibe to access files from both mobile devices and desktop devices.')}
              </p>
              <p>
                {t('textEditorFile.06', 'You can edit and update your stored files by using this text editor.')}
              </p>
              <div>
                <a
                  href={kuraStorageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btnBase">
                    Kura Storage {t('textEditorFile.07', 'Page Link')}
                  </button>
                </a>
              </div>
              <div className="editorAboutKuraStorageImageContainer">
                <a
                  href={kuraStorageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="editorAboutKuraStorageImage"
                    src={KuraStorageImage1}
                    alt="kura storage1"
                    />
                </a>
                <a
                  href={kuraStorageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="editorAboutKuraStorageImage"
                    src={KuraStorageImage2}
                    alt="kura storage2"
                  />
                </a>
              </div>
            </div> */}
          </Modal>
        </div>
    </Fragment>
  );
}

export default EditorAboutKuraStorage;
