// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SrgD03s5ATkYjzC5OnuC {\n  width: 25rem; \n  /* max-width: 100%; */\n  max-width: 80%;\n  margin: 0 auto;\n  padding-top: 7.5px;\n}", "",{"version":3,"sources":["webpack://./src/components/BucketComponents/ObjectSearch/ObjectSearch.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;EACrB,cAAc;EACd,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".adHostSearch {\n  width: 25rem; \n  /* max-width: 100%; */\n  max-width: 80%;\n  margin: 0 auto;\n  padding-top: 7.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adHostSearch": "SrgD03s5ATkYjzC5OnuC"
};
export default ___CSS_LOADER_EXPORT___;
