// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g8CDRfbpIj73sL7q9Ygg {\n  padding: 1rem;\n  font-size: 1.1rem;\n}\n\n.IgYzNwKuhUodZ6moZuM8 {\n  font-size: 1.5rem;\n  font-weight: bold;\n  text-align: center;\n  padding-bottom: 1rem;\n}\n\n.IRpfc2kU2lhhz0eIC6nm {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  padding-bottom: 1rem;\n}\n\n\n.y1HcQFutoBNXIcd1_UQb {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 2rem;\n  align-items: center;\n  justify-content: center;\n  padding-top: 2rem;\n  padding-bottom: 1rem;\n}\n\n.sboj1NiAmyYddCb8uC9b {\n  height: 200px;\n  width: 200px;\n  max-width: 100%;\n  object-fit: cover;\n}\n\n", "",{"version":3,"sources":["webpack://./src/pages/AboutPage/AboutPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,oBAAoB;AACtB;;;AAGA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".aboutPage {\n  padding: 1rem;\n  font-size: 1.1rem;\n}\n\n.aboutPageTitle {\n  font-size: 1.5rem;\n  font-weight: bold;\n  text-align: center;\n  padding-bottom: 1rem;\n}\n\n.aboutPageButtons {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n  padding-bottom: 1rem;\n}\n\n\n.aboutPageImageContainer {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 2rem;\n  align-items: center;\n  justify-content: center;\n  padding-top: 2rem;\n  padding-bottom: 1rem;\n}\n\n.aboutPageImage {\n  height: 200px;\n  width: 200px;\n  max-width: 100%;\n  object-fit: cover;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutPage": "g8CDRfbpIj73sL7q9Ygg",
	"aboutPageTitle": "IgYzNwKuhUodZ6moZuM8",
	"aboutPageButtons": "IRpfc2kU2lhhz0eIC6nm",
	"aboutPageImageContainer": "y1HcQFutoBNXIcd1_UQb",
	"aboutPageImage": "sboj1NiAmyYddCb8uC9b"
};
export default ___CSS_LOADER_EXPORT___;
