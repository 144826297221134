import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_SHEETUPDATETIME: (state, payload) => {
      return { 
        // ...state,
        spreadsheetStore : {
          ...state.spreadsheetStore,
          sheetUpdateTime: payload, 
        }
      };
    },
  };

  initStore(actions, {
    spreadsheetStore: {
      sheetUpdateTime: 0,
    }
  });
};

export default configureStore;
