import React from "react"
import { Block } from "baseui/block"
import Common from "./Common"
import Flip from "./Shared/Flip"
import useAppContext from "~/hooks/useAppContext"
import { useActiveObject, useEditor } from "@layerhub-io/react"

import { StatefulPopover } from "baseui/popover";
import PathFill from "../Panels/panelItems/PathFill";
import Gradient from "../Panels/panelItems/Common/Gradient";

export default function () {
  const [state, setState] = React.useState({ fill: "#000000" })
  const { setActiveSubMenu } = useAppContext()
  const editor = useEditor()
  const activeObject = useActiveObject() as any

  React.useEffect(() => {
    if (activeObject && activeObject.type === "StaticPath") {
      setState({ fill: activeObject.fill })
    }
  }, [activeObject])

  React.useEffect(() => {
    let watcher = async () => {
      if (activeObject && activeObject.type === "StaticPath") {
        setState({ fill: activeObject.fill })
      }
    }
    if (editor) {
      editor.on("history:changed", watcher)
    }
    return () => {
      if (editor) {
        editor.off("history:changed", watcher)
      }
    }
  }, [editor, activeObject])

  return (
    <Block
      $style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "0 12px",
        justifyContent: "space-between",
      }}
    >
      <Block
        $style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Block onClick={() => setActiveSubMenu("PathFill")}>
          <StatefulPopover
            content={({ close }) => (
                <div style={{width: "300px"}}>
                  <PathFill
                    close={close}
                  />
                </div>
            )}
          >
            <Block
              $style={{
                height: "24px",
                width: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: state.fill,
                border: "1px solid #dedede",
              }}
            ></Block>
          </StatefulPopover>
        </Block>
        <Flip />

        {/* <Block 
          // onClick={() => setActiveSubMenu("PathFill")}
        >
          <StatefulPopover
            content={({ close }) => (
                <div style={{width: "300px"}}>
                  <Gradient
                    close={close}
                  />
                </div>
            )}
          >
            gradient
          </StatefulPopover>
        </Block> */}

      </Block>
      <Common />
    </Block>
  )
}
