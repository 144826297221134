import React from 'react';
import { useEffect, useState, useRef } from "react";
import { renderToString } from 'react-dom/server';

// import katex from 'katex';
import { BlockMath, InlineMath } from 'react-katex';
// import { parse, HtmlGenerator } from 'react-katex'

import { Editor } from '@toast-ui/react-editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// import colorSyntax2 from '@toast-ui/editor-plugin-color-syntax2';
import { colorSyntaxPlugin as colorSyntax2  } from './editor-plugin/color2-pg';
import { fontSizePlugin } from './editor-plugin/font-size-plugin';

// import Markdown from './React-katex/Markdown.component';
// import MarkdownRender from './React-katex/MarkdownRender';

import Loader from '../../Loader/Loader';
import { 
  createTextFile, 
  downloadHtlmTextFile,
  addHtmlHandler,
} from './toast-ui-editor-util';
// import 'tui-color-picker/dist/tui-color-picker.css';
// import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import './tui-color-picker.css';
import './toastui-editor-plugin-color-syntax.css'

// import '@toast-ui/editor/dist/toastui-editor.css';
import './toastui-editor.css';



function ToastUiEditorWYSIWYG() {

  const editorWyRef = React.createRef();

  const [htmlContent, setHtmlContent] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    // handleWyClick2();

    const editor = editorWyRef.current.getInstance();
    console.log(editor);

    console.log(editor.getHTML());
    console.log(editor.getMarkdown())

    // const color2Icon = document.querySelector('[aria-label="color2"]');
    // console.log(color2Icon);
    // color2Icon.textContent = 'C2'

    // const spanEl = <span>c-2</span>;
    // // const spanEl = <i class="fa fa-link" aria-hidden="true"></i>
    // // const htmlString = renderToStaticMarkup(spanEl);
    // const htmlString = renderToString(spanEl);
    // color2Icon.innerHTML = htmlString;

    // editorInstance.insertToolbarItem({ groupIndex: 0, itemIndex: 0 }, {
    //   name: 'myItem',
    //   tooltip: 'Custom Button',
    //   // command: 'bold',
    //   command: 'color2',
    //   text: '@',
    //   className: 'toastui-editor-toolbar-icons first',
    //   style: { backgroundImage: 'none' }
    // });

  },[]);


  useEffect(() => {

    // const popup = document.createElement('ul');
    // // ...

    // editor.addWidget(popup, 'top');
    const editor = editorWyRef.current.getInstance();

    editor.on('keyup', (editorType, ev) => {
      if (ev.key === '@') {
        console.log(ev);
        const popup = document.createElement('ul');
        // ...
        const liEl = popup.appendChild(document.createElement('li'));
        const liEl2 = popup.appendChild(document.createElement('li'));
        liEl.textContent = 'popup-c1'
        liEl2.textContent = 'popup-c2'
        console.log(liEl, popup);
        editor.addWidget(popup, 'top');

        popup.addEventListener('mousedown', (ev) => {
          const text = ev.target.textContent.replace(/\s/g, '').replace(/😎/g, '');
          const [start, end] = editor.getSelection();
        
          editor.replaceSelection(`[@${text}](${text})`, [start[0], start[1] - 1], end);
        });
      }
    })
  },[]);

  // const handleClick = () => {
  //   editorRef.current.getInstance().exec('bold');
  // };

  const handleFocus = () => {
    console.log('focus!!');
  };

  const handlerGetHtml = () => {
    const editorInstance = editorWyRef.current.getInstance();
    console.log(editorInstance);

    console.log(editorInstance.getHTML());
    console.log(editorInstance.getMarkdown());

    setHtmlContent(editorInstance.getHTML());
    // localStorage.setItem('htmlContent', editorInstance.getHTML());
  };

  const handleSetHtml = (html) => {
    const editor = editorWyRef.current.getInstance();
    editor.setHTML(html);
    // editor.setHTML(localStorage.getItem('htmlContent'));
  };

  const handleReplaceSelection = (text) => {
    const editor = editorWyRef.current.getInstance();
    // editor.replaceSelection(text);

    const wwSelection = editor.getSelection();
    console.log(wwSelection);
    editor.replaceWithWidget(wwSelection[0], wwSelection[1], text);
  };

  const handleGetSelection = () => {
    const editor = editorWyRef.current.getInstance();

    console.log(editor.getSelection(), editor.getHTML());

    // editor.exec('customBlock', { info: 'latex' });
    // editor.insertText('what you want')
    editor.changeMode('markdown');
    editor.insertText('<span style="color: green">makdown-mode-text-span</span>');
    editor.changeMode('wysiwyg');
  }

  // const insertHtmlHandler = (htmlElementString) => {
  //   const editor = editorWyRef.current.getInstance();

  //   editor.changeMode('markdown');
  //   editor.insertText(htmlElementString);
  //   editor.changeMode('wysiwyg');
  // };

  const handleAddLink = (url, text) => {
    const editor = editorWyRef.current.getInstance();
    const attrs = {
      linkUrl: `${url}`,
      linkText: `${text}`,
    };
    editor.exec('addLink', attrs);
  }

  const fetchTextFileHandler = async () => {
    try {
      setIsLoading(true);
      const editor = editorWyRef.current.getInstance();

      // const result = await fetch('https://kmpartner-do-test-dev-space.sfo2.digitaloceanspaces.com/texthtml3.txt');
      const result = await fetch('https://kmpartner-do-test-dev-space.sfo2.digitaloceanspaces.com/dtexthtml.txt');
      const resData = await result.text();

      if (!result.ok) {
        throw new Error('error occured');
      }

      console.log(resData);

      editor.setHTML(resData);
      setHtmlContent(resData);

      setIsLoading(false);
      return resData;

    } catch(err){
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };




  const handleWyClick = () => {
    // editorWyRef.current.getInstance().exec('bold');
    const wyInstance = editorWyRef.current.getInstance();
  
    const mode = wyInstance.getCurrentPreviewStyle();
    console.log(mode);
    // wyInstance.exec('bold');
  };

  const handleWyClick2 = () => {
    const modeSwitchEl = document.getElementsByClassName('toastui-editor-mode-switch');
    // const modeSwitchEl = document.querySelector('.toastui-editor-mode-switch');
    // console.log(modeSwitchEl);
    if (modeSwitchEl[0]) {
      if (modeSwitchEl[0].style.display === 'none') {
        modeSwitchEl[0].style.display = 'block';  
      } else {
        modeSwitchEl[0].style.display = 'none';
      }
    }
  };


  // const html = katex.renderToString('\\ce{CO2 + C -> 2 C0}');
  // console.log(html);


  // Step 1: Define the user plugin function
  function latexPlugin() {
    const toHTMLRenderers = {
      latex(node) {
        console.log(node, node.literal);

        const string = node.literal;
        // const generator = new latexjs.HtmlGenerator({ hyphenate: false });
        // const { body } = latexjs.parse(node.literal, { generator }).htmlDocument();
        // const bEl = <BlockMath>{'s = ut + \\frac{1}{2}at^{3}'}</BlockMath>
        // const bEl = <BlockMath>{'f(x) = \\int_{-\\infty}^\\infty \\hat f(\\xi)\,e^{2 \\pi i \\xi x} \, d\\xi'}</BlockMath>
        const bEl = <BlockMath>{string}</BlockMath>
        const htmlString = renderToString(bEl);
        console.log(htmlString)
        var body = new DOMParser().parseFromString(htmlString, "text/html");
        
        console.log(body);
        return [
          { type: 'openTag', tagName: 'span', outerNewLine: false },
          { type: 'html', content: body.firstChild.innerHTML },
          // { type: 'html', content: '<span style="color: red;">abc-def</span>' },
          { type: 'closeTag', tagName: 'span', outerNewLine: false }
        ];
      },
    }

    return { toHTMLRenderers }
  }

  const content = [
    '$$latex',
    '\\documentclass{article}',
    '\\begin{document}',
    '',
    '$',
    'f(x) = \\int_{-\\infty}^\\infty \\hat f(\\xi)\,e^{2 \\pi i \\xi x} \, d\\xi',
    '$',
    '\\end{document}',
    '$$'
  ].join('\n');


  function testPlugin() {
    const toHTMLRenderers = {
      tpg(node) {
        // const generator = new latexjs.HtmlGenerator({ hyphenate: false });
        // const { body } = latexjs.parse(node.literal, { generator }).htmlDocument();
        
        // console.log(generator, body);
        // return {
        //   type: 'openTag',
        //   tagName: 'br',
        //   classNames: ['my-class'],
        //   selfClose: true
        // }

        return [
          { type: 'openTag', tagName: 'span', outerNewLine: false },
          // { type: 'html', content: body.innerHTML },
          { type: 'html', content: '<span style="color: red;">test-plugin-content</span>' },
          { type: 'closeTag', tagName: 'span', outerNewLine: false }
        ];

        // return [
        //   { type: 'openTag', tagName: 'span', outerNewLine: true },
        //   // { type: 'html', content: body.innerHTML },
        //   { type: 'html', content: '<span style="color: red;">test-plugin-content</span>' },
        //   { type: 'closeTag', tagName: 'span', outerNewLine: true }
        // ];
      },
    }

    return { toHTMLRenderers }
  }

  const reWidgetRule = /\[(@\S+)\]\((\S+)\)/;

  const contentK = `
  **Below me will be displayed as a block:**
$$
Block Math
$$
**Next to me will be displayed as inline ->** $Inline Math$
`;

  return (
    <div>
      <Editor
        ref={editorWyRef}
        initialValue="hello react editor world!"
        previewStyle="vertical"
        height="80vh"
        // initialEditType="markdown"
        initialEditType="WYSIWYG"
        useCommandShortcut={true}

        onFocus={handleFocus}
        plugins={[
          colorSyntax, 
          // colorSyntax2,
          // fontSizePlugin,
          latexPlugin,
          testPlugin,
        ]}
        // onChange={handleFocus}
        customHTMLRenderer={{
          htmlBlock: {
            iframe(node) {
              console.log(node);
              return [
                { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                { type: 'html', content: node.childrenHTML },
                { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
              ];
            },
            htmlInline: {
              big(node, { entering }) {
                return entering
                  ? { type: 'openTag', tagName: 'big', attributes: node.attrs }
                  : { type: 'closeTag', tagName: 'big' };
              },
              
            },
          }
        }}
        widgetRules={
          [
            {
              rule: reWidgetRule,
              toDOM(text) {
                const rule = reWidgetRule;
                const matched = text.match(rule);
                const span = document.createElement('span');
          
                span.innerHTML = `<a class="widget-anchor" href="${matched[2]}">${matched[1]}</a>`;
                return span;
              },
            },
          ]
        }
        // toolbarItems={[
        //   ['heading', 'bold', 'italic', 'strike'],
        //   ['hr', 'quote'],
        //   ['ul', 'ol', 'task', 'indent', 'outdent'],
        //   ['table', 'image', 'link'],
        //   ['code', 'codeblock'],
        //   // Using Option: Customize the last button
        //   [{
        //     el: createLastButton(),
        //     command: 'color2',
        //     tooltip: 'color2'
        //   }]
        // ]}
      />
      {/* <button onClick={handleWyClick}>Wy click</button>
        <bu<tton onClick={handleWyClick2}>Wy click2</button> */}
      <button onClick={handlerGetHtml}>getHtml</button>  
      <button 
        onClick={() => { handleSetHtml('<p>abc</p><p>def</p><p><br></p><p><span style="color: #f71818">ghi</span></p>')}}
      >
        setHtml
      </button>  

      <button onClick={fetchTextFileHandler}>fetchTextHtml</button> 
      <button 
        onClick={() => {
          createTextFile(htmlContent, 'texthtml');
        }}
        >
          create-text-file
      </button> 
      <button 
        onClick={() => {
          downloadHtlmTextFile(htmlContent, 'dtexthtml');
        }}
        >
          download-html-text-file
      </button> 
      <button 
        onClick={() => {
          // const myHtmlString = '<p><span class="text">Hello World!</span></p>'
          // const htmlDom = new DOMParser().parseFromString(myHtmlString, 'text/html');
          // console.log(htmlDom.documentElement.innerHTML);
          const spanEl = (
              <span style={{color: "red", fontSize:"20px"}}>
                span-element
              </span>
          );
          // console.log(spanEl);
          // console.log(renderToString(spanEl));

          // insertHtmlHandler('<span style="color: red; font-size: 25px; font-weight: bold;">insert color text</span>');
          addHtmlHandler(
            editorWyRef.current.getInstance(),
            renderToString(spanEl)
          );
        }}
        >
          insert-html-text
      </button> 
      <button 
        onClick={() => {
          handleGetSelection()
        }}
        >
          get-selection
      </button> 
      <button 
        onClick={() => {
          handleAddLink(
            'http://localhost:3000', 
            editorWyRef.current.getInstance().getSelectedText(),
            );
        }}
        >
          add-link
      </button> 
      {isLoading && (<Loader />)}
      <div>htlm-content : {htmlContent}</div>
      <BlockMath>{'s = ut + \\frac{1}{2}at^{2}'}</BlockMath>
      {/* <Markdown>{contentK}</Markdown>
      <MarkdownRender 
        source={`$\sigma_U \sim \mathrm{Normal}(0, \Theta_U^2)$`} 
      />
      <MarkdownRender 
        source={'s = ut + \\frac{1}{2}at^{2}'} 
      /> */}
      {/* <BlockMath>{content}</BlockMath> */}

    </div>
  );
}

export default ToastUiEditorWYSIWYG;