// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FMALvcOMFyvNqHOitl3s {\n    min-height: 100vh;\n    padding: 1rem;\n}\n\n.uv2d2AmvVpzGC9YV8yqf {\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n    padding: 1rem 0;\n}\n\n.cvOpUnvnX6FGErsJ8ODB {\n    font-size: 1.25rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Auth/AuthRequired.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".authRequired {\n    min-height: 100vh;\n    padding: 1rem;\n}\n\n.authRequiredButtons {\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n    padding: 1rem 0;\n}\n\n.authRequiredAboutLink {\n    font-size: 1.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authRequired": "FMALvcOMFyvNqHOitl3s",
	"authRequiredButtons": "uv2d2AmvVpzGC9YV8yqf",
	"authRequiredAboutLink": "cvOpUnvnX6FGErsJ8ODB"
};
export default ___CSS_LOADER_EXPORT___;
