import React, {Fragment, useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import DragAndDropModule from 'quill-drag-and-drop-module';

import PlainClipboard from '../PlainClipboard'

import EditorFileControl from '../FileControl/EditorFileControl';
// import QuillFileControl from './QuillFileControl';

import { useStore } from '../../../../hook-store/store';



// import ReactContextMenu from '../FileControl/ReactContextMenu/ReactContextMenu';





import './quill.snow.css';

const Quill = ReactQuill.Quill
var Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'serif', 'monospace'];
Quill.register(Font, true);

var icons = Quill.import("ui/icons");
// icons["undo"] = 'UNDO';
icons["undo"] = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
  <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
  <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;


Quill.register('modules/clipboard', PlainClipboard, true)


var Size = Quill.import('attributors/style/size');
Size.whitelist = ['', '8pt', '10pt', '12pt','16pt', '20pt', '24pt', '36pt', '48pt', '64pt' ];
// Size.whitelist = ['', '16pt', '24pt', '32pt'];
Quill.register(Size, true);







function QuillEditor(props) {

  const editorRef = useRef();

  const [store, dispatch] = useStore();
  const { loadedData } = store.toolsStore;
  const { editorHtml } = store.textEditorStore;

  const { isAuth } = store.uiStore;

  // const [editorHtml, setEditorHtml] = useState('');
  const [theme, setTheme] = useState('snow');
  const [quillLoaded, setQuillLoade] = useState(false);
  
  useEffect(() => {
    console.log(editorRef.current);
    const quill = editorRef.current.getEditor();
    const oldHtml = quill.root.innerHTML;

    console.log(quill, oldHtml, quill.getContents())

    if (quill) {
      setQuillLoade(true);
    }

    const unprivilegedEditor = editorRef.current.makeUnprivilegedEditor(quill);
    // You may now use the unprivilegedEditor proxy methods
    console.log(unprivilegedEditor.getHTML());

    const quillEditorEls = document.getElementsByClassName('ql-editor');
    console.log(quillEditorEls);
    if (quillEditorEls) {
      // quillEditorEls[0].style.minHeight = '75vh';
    }

    quill.focus();
  },[]);

  useEffect(() => {
    // console.log(editorHtml);
    const quill = editorRef.current.getEditor();
    console.log(quill.getContents());
  },[editorHtml]);

  //// load html text data
  useEffect(() => {
    if (loadedData && loadedData.startsWith('<')) {
      // setEditorHtml(loadedData);
      dispatch('SET_EDITORHTML', loadedData);
    }
  },[loadedData]);

  useEffect(() => {
      // setEditorHtml('some text \n this is some text\n\n   next some text')
  },[]);

  useEffect(() => {
    if (quillLoaded) {
      const undoEls = document.getElementsByClassName('ql-undo');
      const redoEls = document.getElementsByClassName('ql-redo');
      // console.log(undoEl);
      undoEls[0].addEventListener('click', (event) => {
        // console.log('click')
        const quill = editorRef.current.getEditor();
        quill.history.undo();

        // const quillDelta = quill.getContents();

        // //// redo when no content
        // if (quillDelta.ops.length === 1 && quillDelta.ops[0].insert === "\n") {
        //   quill.history.redo();
        // }
      });

      redoEls[0].addEventListener('click', (event) => {
        // console.log('click')
        const quill = editorRef.current.getEditor();
        quill.history.redo();
      });
    }
  },[quillLoaded]);



  const handleChange = (html) => {
  	// setEditorHtml(html);
    dispatch('SET_EDITORHTML', html);
  };
  
  const handleThemeChange = (newTheme) => {
    // if (newTheme === "core") newTheme = null;
    setTheme(newTheme);
  };

  const buttonClick = () => {
    const quill = editorRef.current.getEditor();
    const oldHtml = quill.root.innerHTML;
    console.log(oldHtml);
    // const newElement = createElementWithClassName();
    // const newHtml = oldHtml + newElement;
    // setContent(newHtml);
  };

  const undoHandler = () => {
    const quill = editorRef.current.getEditor();
    quill.history.undo();
  };

  const redoHandler = () => {
    const quill = editorRef.current.getEditor();
    quill.history.redo();
  };
  

    return (
      <Fragment>
        <div>
  
          {/* <QuillFileControl 
            editorRef={editorRef}
          /> */}

   
          <EditorFileControl 
            editorRef={editorRef}
          />

    
          
          {isAuth && (
            <ReactQuill 
              theme={theme}
              onChange={handleChange}
              value={editorHtml}
              modules={modules}
              formats={formats}
              bounds={'.app'}
              placeholder={props.placeholder}
              ref={editorRef}
              preserveWhitespace={true}
            />
          )}
          {!isAuth && (
            <ReactQuill 
              theme={theme}
              onChange={handleChange}
              value={editorHtml}
              modules={noAuthModules}
              formats={formats}
              bounds={'.app'}
              placeholder={props.placeholder}
              ref={editorRef}
              preserveWhitespace={true}
            />
          )}

          {/* <div style={{position:"fixed", bottom:"200px"}}>
            <ReactContextMenu 
              editorRef={editorRef}
            />
          </div> */}
        </div>
      </Fragment>
     )
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  // toolbar: [
  //   [{ 'header': '1'}, {'header': '2'}, 
  //   // { 'font': Font.whitelist }
  //     // {font: ['Sans Serif', 'Serif', 'Monospace']}
  //   ],
  //   [{size: []}],
  //   ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //   [{'list': 'ordered'}, {'list': 'bullet'}, 
  //    {'indent': '-1'}, {'indent': '+1'}],
  //   ['link', 'image', 'video'],
  //   [{ 'script': 'sub'}, { 'script': 'super' }], 
  //   [{ 'color': [] }, { 'background': [] }], 
  //   [{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
  //   ['clean'],
  //   ['undo', 'redo'],
  // ],
  toolbar: { 
    container: [
      [
        // { 'header': '1'}, {'header': '2'}, 
        { 'font': Font.whitelist }
        // {font: ['Sans Serif', 'Serif', 'Monospace']}
      ],
      // [{ 'header': [1, 2, false] }],
      [{ size: Size.whitelist }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
      // ['link', 'image', 'video'],
      ['link', 'image'],
      [{ 'script': 'sub'}, { 'script': 'super' }], 
      [{ 'color': [] }, { 'background': [] }], 
      [{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      ['clean'],
      ['undo', 'redo'],
      // [{ 'size': Size.whitelist }],
    ],
    handlers: {
      // 'undo' : undoHandler,
      // 'redo' : redoHandler,
      }
  },
  dragAndDrop: {
    draggables: [
      {
        content_type_pattern: DragAndDropModule.image_content_type_pattern,
        tag: 'img',
        attr: 'src'
      },
    ],
    onDrop(file) {
      return DragAndDropModule.utils.getFileDataUrl(file)
      .then(base64 => { 
        console.log(base64)
      })
      // .then(response_from_server => response_from_server.url_of_resource);
      .catch(err => {
        console.log(err);
      })
    }
  },
}




const noAuthModules = {
  toolbar: { 
    container: [
      [
        { 'header': '1'}, {'header': '2'}, 
        // { 'font': Font.whitelist }
        // {font: ['Sans Serif', 'Serif', 'Monospace']}
      ],
      [{ 'header': [1, 2, false] }],
      // [{ size: Size.whitelist }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
      // ['link', 'image', 'video'],
      ['link', 'image'],
      [{ 'script': 'sub'}, { 'script': 'super' }], 
      [{ 'color': [] }, { 'background': [] }], 
      [{ 'align': null}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      ['clean'],
      ['undo', 'redo'],
    ],
    handlers: {
      // 'undo' : undoHandler,
      // 'redo' : redoHandler,
      }
  },
  dragAndDrop: {
    draggables: [
      {
        content_type_pattern: DragAndDropModule.image_content_type_pattern,
        tag: 'img',
        attr: 'src'
      },
    ],
    onDrop(file) {
      return DragAndDropModule.utils.getFileDataUrl(file)
      .then(base64 => { 
        console.log(base64)
      })
      // .then(response_from_server => response_from_server.url_of_resource);
      .catch(err => {
        console.log(err);
      })
    }
  },
};

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
  'script', 
  'color', 'background',
  'align'
]

// const undoHandler = (quill) => {
//   // const quill = editorRef.current.getEditor();
//   quill.history.undo();
// };

/* 
 * PropType validation
 */
// Editor.propTypes = {
//   placeholder: React.PropTypes.string,
// }

/* 
 * Render component on page
 */
// ReactDOM.render(
//   <Editor placeholder={'Write something...'}/>, 
//   document.querySelector('.app')
// )

export default QuillEditor;