import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_LOADEDIMAGENAME: (state, payload) => {
      return { 
        imageEditorStore : {
          ...state.imageEditorStore,
          loadedImageName: payload, 
        }
      };
    },
    SET_UNDOSTACKLENGTH: (state, payload) => {
      return { 
        imageEditorStore : {
          ...state.imageEditorStore,
          undoStackLength: payload, 
        }
      };
    },
    SET_INITIALWIDTH: (state, payload) => {
      return { 
        imageEditorStore : {
          ...state.imageEditorStore,
          initialWidth: payload, 
        }
      };
    },
    // SET_EDITORREF: (state, payload) => {
    //   return { 
    //     imageEditorStore : {
    //       ...state.imageEditorStore,
    //       editorRef: payload, 
    //     }
    //   };
    // },

    // SET_EDITFILE: (state, payload) => {
    //   return { 
    //     imageEditorStore : {
    //       ...state.imageEditorStore,
    //       editFile: payload, 
    //     }
    //   };
    // },


  };

  initStore(actions, {
    imageEditorStore: {
      loadedImageName: '',
      undoStackLength: 0,
      initialWidth: 0,
      // editorRef: null,
      // editFile: null
    }

  });
};

export default configureStore;
