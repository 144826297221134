import React, {Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../../hook-store/store';
import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';
import { s3fileUpdate } from '../../../../../utils/bucket/bucket-upload-util';
import { 
  getLoadObjectInfo, 
  setUpdatedToken,
  getLoadData,
} from '../../../../../utils/tools-bucket-object/tools-bucket-object-util';

// import { 
//   // convertOdtToHtml,
//   convertHtmlFileToPdf,
//   convertFileToPdf,
//  } from '../../../../../utils/tools-bucket-object/tools-file-convert-util';

import { BACKEND_URL } from '../../../../../App';

import { marks } from '../../../../../utils/marks';
// import './EditorFileSave.css';
import '../EditorFileMenu.css';

function EditorFileSave(props) {
  const { 
    editorRef,
    // editorHtml,
    // setEditorHtml
   } = props;

  const [t] = useTranslation('translation');

  const importRef1 = useRef(null);

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  const { editorHtml, textUpdateTime } = store.textEditorStore;
  const { loadedObjectInfo, loadedToken } = store.toolsStore;

  // const [editorHtml, setEditorHtml] = useState('');
  // const [theme, setTheme] = useState('snow');
  const [isLoading, setIsLoading] = useState(false);
  
  
  let canUpdate = false;
  
  if ((loadedObjectInfo && loadedObjectInfo.shareType === 'write') ||
      (loadedObjectInfo && loadedObjectInfo.bucket === loadedObjectInfo.userId)
  ) {
      canUpdate = true;
  }

  // useEffect(() => {
  //   setInterval(() => {
  //     textDataUpdateHandler();
  //   }, 1000 * 60 * 10);
  // },[]);

  const buttonClick = () => {
    const quill = editorRef.current.getEditor();
    const oldHtml = quill.root.innerHTML;
    console.log(oldHtml);

    const quillInstance = editorRef.current.getEditor();
    const quillDelta = quillInstance.getContents();
    console.log(quillDelta);
    // const loadedData = getLoadObjectInfo(localStorage.getItem('token'));
    // console.log('loadedData', loadedData);

    // const fileName = loadedData.key.split('/')[loadedData.key.split('/').length - 1];
    // const timeEndIndex = fileName.indexOf('Z');
    // const originalName = fileName.substring(timeEndIndex+2);

    // const file = createTextFile(oldHtml, originalName);
    // console.log(file);

    // download(file, originalName, 'text/plain');
    // const newElement = createElementWithClassName();
    // const newHtml = oldHtml + newElement;
    // setContent(newHtml);
  };
  

  const textDataUpdateHandler = async () => {
    try {

      if (!isAuth) {
        console.log('not-is-auth')
        return;
      }

      const loadedData = loadedObjectInfo;
      
      if (!loadedData) {
        return;
      }

      const key = loadedData.key;

      let path = '';
      const keySplitList = key.split('/');
      const fileName = key.split('/')[key.split('/').length -1];
      const timeEndIndex = fileName.indexOf('Z');
      const originalName = fileName.substring(timeEndIndex+2);

      if (keySplitList.length > 1) {
        keySplitList.pop();
        path = keySplitList.join('/') + '/';
      }

      // let file;

      // if (loadedData.mimeType && loadedData.mimeType.split('/')[0] === 'text') {
      //   file = createTextFile(editorHtml, originalName);
      // }

      // if (loadedData.mimeType && loadedData.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      //   //// create word file
      //   file = await convertToWordFile(originalName);
      // }

      const file = createTextFile(editorHtml, originalName);

      console.log(file);

      console.log(fileName, originalName, keySplitList, loadedData);

      // return;
      // download(file, `lsheet-data.txt`, 'text/plain');
      // return;

      const result = await s3fileUpdate(
        BACKEND_URL,
        loadedToken,
        path,
        key,
        [file],
        loadedData.bucket,
        dispatch,
      );

      if (result.data && result.data.data && result.data.data[0]) {
        console.log(result, result.data.data[0]);
        const updatedToken = result.data.data[0].token;
  
        // setUpdatedToken(updatedToken);
        
        const decodedToken = getLoadObjectInfo(updatedToken);

        dispatch('SET_LOADEDOBJECTINFO', decodedToken);
        dispatch('SET_LOADEDTOKEN', updatedToken);

        console.log(decodedToken);
  
        await updateLoadDataHandler(
          BACKEND_URL,
          updatedToken,
          decodedToken.bucket,
          decodedToken.key,
          decodedToken.mimeType,
        );

        dispatch('SET_TEXTUPDATETIME', Date.now());

      }

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'update file failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };


  const updateLoadDataHandler = async (url, token, bucket, key, mimeType) => {
    try {
      setIsLoading(true);

      const resData = await getLoadData(url, token, bucket, key, mimeType);

      if (resData.data) {
        dispatch('SET_LOADEDDATA', resData.data);

        // if (resData.data.startsWith('{')) {
        //   navigate('/spreadsheet');
        // }

        // if (resData.data.startsWith('<')) {
        //   navigate('/text-editor');
        // }
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      // throw err;
    }
  };




  const getLoadedTokenData = () => {
    const loadedData = loadedObjectInfo;
    console.log(loadedData);
  }

  // const onButtonClick = () => {
  //   // `current` points to the mounted file input element
  //   importRef1.current.click();
  // };

  let editorFileControlBody;

  if (!isAuth) {
    editorFileControlBody = (
      <button className='editorFileMeunButton'
        // disabled={!isAuth}
        onClick={() => {
          dispatch('SET_SHOWABOUTKURASTORAGE', true);
        }}
    >
      {t('textEditorFile.01', 'save file')} {marks.infoCircleMark}
    </button>
      // <EditorAboutKuraStorage />
    );
  }

  if (isAuth && editorHtml) {
    editorFileControlBody = (
        <button className='editorFileMeunButton'
          disabled={isLoading || !canUpdate}
          onClick={() => {
            textDataUpdateHandler();
          }}
        >
          {t('textEditorFile.01', 'save file')}
        </button>
    );
  }


  return (
    <Fragment>
        <div>
          {editorFileControlBody}


          {/* <div>
            text-editor-content
          </div>
          <button onClick={buttonClick}>
            get-data
          </button>

          <button
            onClick={getLoadedTokenData}
          >
            get-loaded-data
          </button> */}

          
        </div>

    </Fragment>
    )
}


export default EditorFileSave;