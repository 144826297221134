import ReactDOM from "react-dom/client"
import Provider from "./Provider"
import App from "./App"
import Container from "./Container"
// import "./styles/styles.css"

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import '@stripe/stripe-js';


import './i18n';

import { SUBSCRIPTION_BACKEND_URL } from "./App";

import './index.css';

import configureUiStore from './hook-store/ui-store';
import configureBucketObjectStore from './hook-store/bucket-object-store';
import configureAdminStore from './hook-store/adimin-store';
import configureStripeDataStore from "./hook-store/stripe-data-store";
import configureImageEditorStore from './hook-store/image-editor-store';
import configureServiceBucketsStore from './hook-store/service-buckets-store';
import configureToolsStore from './hook-store/tools-store';
import configureTextEditorStore from './hook-store/text-editor-store';
import configureSpreadsheetStore from './hook-store/spreadsheet-store';
import configurePresentationStore from './hook-store/presentation-store';

configureUiStore();
configureBucketObjectStore();
configureAdminStore();
configureStripeDataStore();
configureImageEditorStore();
configureServiceBucketsStore();
configureToolsStore();
configureTextEditorStore();
configureSpreadsheetStore();
configurePresentationStore();


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider>
    <Container>
      <App />
    </Container>

    {/* <App /> */}
  </Provider>
)


//// load stripe publishableKey
fetch(SUBSCRIPTION_BACKEND_URL + '/price/config')
  .then(r => {
    return r.json()
  })
  .then(resData => {
    console.log('stripe-config resData', resData);
    const stripePromise = loadStripe(resData.publishableKey);

    root.render(
      // <React.StrictMode>
      <Elements stripe={stripePromise}>
          <Provider>
            <Container>
              <App />
            </Container>
          </Provider>
      </Elements>
      // </React.StrictMode>
    );

  })
  .catch(err => {
    // console.log('stripe-config err', err)
  });