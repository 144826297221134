import axios from 'axios';

export const convertOdtToHtml = async (url, token, files, bucket, ) => {
  try {

    const data = new FormData(); 
    console.log(files);

    for (const file of files) {
      data.append('files', file);
    }

    data.append('bucket', bucket);

    // data.append('files', selectedFiles);
    // data.append('storePath', path);
    // data.append('oldKey', oldKey);
    
    // console.log('data', data, data.getAll('files'));
    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    const result = await axios.request({
      method: 'post',
      url: url + "/file-convert/odt-html",
      data: data,
      headers: headers,
      onUploadProgress: (p) => {
        // // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

        // let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

        // if (p.loaded/p.total === 1) {
        //   message = 'Uploaded, please wait until storing files finish...'
        // }

        // dispatch('SHOW_NOTIFICATION', {
        //   status: '',
        //   title: message,
        //   message: '',
        // });
      }
    })

    console.log(result);

    if (result.data && result.data.data) {
      // dispatch('SET_EDITORHTML', result.data.data);
      return result.data.data
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const convertHtmlFileToPdf = async (url, token, files, bucket, key) => {
  try {

    const data = new FormData(); 
    console.log(files);

    for (const file of files) {
      data.append('files', file);
    }

    data.append('bucket', bucket);
    data.append('key', key);

    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    const result = await axios.request({
      method: 'post',
      url: url + "/file-convert/html-file-to-pdf",
      data: data,
      headers: headers,
      onUploadProgress: (p) => {
        // // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

        // let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

        // if (p.loaded/p.total === 1) {
        //   message = 'Uploaded, please wait until storing files finish...'
        // }

        // dispatch('SHOW_NOTIFICATION', {
        //   status: '',
        //   title: message,
        //   message: '',
        // });
      }
    })

    console.log(result);

    if (result.data && result.data.data) {
      // dispatch('SET_EDITORHTML', result.data.data);
      return result.data.data
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const convertFileToPdf = async (url, token, files, bucket, ) => {
  try {

    const data = new FormData(); 
    console.log(files);

    for (const file of files) {
      data.append('files', file);
    }

    data.append('bucket', bucket);

    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    const result = await axios.request({
      method: 'post',
      url: url + "/file-convert/file-to-pdf",
      data: data,
      headers: headers,
      onUploadProgress: (p) => {
        // // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

        // let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

        // if (p.loaded/p.total === 1) {
        //   message = 'Uploaded, please wait until storing files finish...'
        // }

        // dispatch('SHOW_NOTIFICATION', {
        //   status: '',
        //   title: message,
        //   message: '',
        // });
      }
    })

    console.log(result);

    if (result.data && result.data.data) {
      // dispatch('SET_EDITORHTML', result.data.data);
      return result.data.data
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const convertHtmlFileToDocx = async (url, token, files, bucket, key) => {
  try {

    const data = new FormData(); 
    console.log(files);

    for (const file of files) {
      data.append('files', file);
    }

    data.append('bucket', bucket);
    data.append('key', key);

    const headers = {
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    };
  
    const result = await axios.request({
      method: 'post',
      url: url + "/file-convert/html-file-to-docx",
      data: data,
      headers: headers,
      onUploadProgress: (p) => {
        // // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

        // let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

        // if (p.loaded/p.total === 1) {
        //   message = 'Uploaded, please wait until storing files finish...'
        // }

        // dispatch('SHOW_NOTIFICATION', {
        //   status: '',
        //   title: message,
        //   message: '',
        // });
      }
    })

    console.log(result);

    if (result.data && result.data.data) {
      // dispatch('SET_EDITORHTML', result.data.data);
      return result.data.data
    } else {
      throw new Error('error occured');
    }

  } catch(err) {
    console.log(err);
    throw err;
  }
};