import React from "react"
import { useActiveObject, useEditor } from "@layerhub-io/react"
// import getSelectionType from "~/utils/get-selection-type"
import { Input } from "baseui/input"
import { Block } from "baseui/block"
import { ChevronDown } from "baseui/icon"
import Common from "../Common"
import TextColor from "~/components/Icons/TextColor"
import Bold from "~/components/Icons/Bold"
import Italic from "~/components/Icons/Italic"
import Underline from "~/components/Icons/Underline"
import TextAlignCenter from "~/components/Icons/TextAlignCenter"

import { Button, SIZE, KIND } from "baseui/button"
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip"
import LetterCase from "~/components/Icons/LetterCase"
import Spacing from "~/components/Icons/Spacing"
import { StatefulPopover } from "baseui/popover"
import TextAlignJustify from "~/components/Icons/TextAlignJustify"
import TextAlignLeft from "~/components/Icons/TextAlignLeft"
import TextAlignRight from "~/components/Icons/TextAlignRight"
import { Slider } from "baseui/slider"
import useAppContext from "~/hooks/useAppContext"
import { FONT_SIZES, SAMPLE_FONTS } from "~/constants/editor"
import getSelectionType from "~/utils/get-selection-type"
import { IStaticText } from "@layerhub-io/types"
import { getTextProperties } from "../../../utils/text"
import { loadFonts } from "~/utils/fonts"
import Scrollbar from "@layerhub-io/react-custom-scrollbar"


const TEXT_ALIGNS = ["left", "center", "right", "justify"]

function TextAlignChange(props) {

  const editor = useEditor()
  const activeObject = useActiveObject()
  const [state, setState] = React.useState<{ align: string }>({ align: "left" })

  React.useEffect(() => {
    if (activeObject) {
      // @ts-ignore
      setState({ align: activeObject.textAlign })
    }
  }, [activeObject])
  return (
    <StatefulPopover
      showArrow={true}
      placement={PLACEMENT.auto}
      content={() => (
        <Block
          padding="12px"
          backgroundColor="#ffffff"
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr 1fr"
          gridGap="8px"
        >
          <Button
            isSelected={state.align === TEXT_ALIGNS[0]}
            onClick={() => {
              // @ts-ignore
              editor.objects.update({ textAlign: TEXT_ALIGNS[0] })
              setState({ align: TEXT_ALIGNS[0] })
            }}
            kind={KIND.tertiary}
            size={SIZE.mini}
          >
            <TextAlignLeft size={24} />
          </Button>
          <Button
            isSelected={state.align === TEXT_ALIGNS[1]}
            onClick={() => {
              // @ts-ignore
              editor.objects.update({ textAlign: TEXT_ALIGNS[1] })
              setState({ align: TEXT_ALIGNS[1] })
            }}
            kind={KIND.tertiary}
            size={SIZE.mini}
          >
            <TextAlignCenter size={24} />
          </Button>
          <Button
            isSelected={state.align === TEXT_ALIGNS[2]}
            onClick={() => {
              // @ts-ignore
              editor.objects.update({ textAlign: TEXT_ALIGNS[2] })
              setState({ align: TEXT_ALIGNS[2] })
            }}
            kind={KIND.tertiary}
            size={SIZE.mini}
          >
            <TextAlignRight size={24} />
          </Button>
          <Button
            isSelected={state.align === TEXT_ALIGNS[3]}
            onClick={() => {
              // @ts-ignore
              editor.objects.update({ textAlign: TEXT_ALIGNS[3] })
              setState({ align: TEXT_ALIGNS[3] })
            }}
            kind={KIND.tertiary}
            size={SIZE.mini}
          >
            <TextAlignJustify size={24} />
          </Button>
        </Block>
      )}
      returnFocus
      autoFocus
    >
      <Block>
        <StatefulTooltip placement={PLACEMENT.left} showArrow={true} accessibilityType="tooltip" content="Align">
          <Button size={SIZE.mini} kind={KIND.tertiary}>
            <TextAlignCenter size={24} />
          </Button>
        </StatefulTooltip>
      </Block>
    </StatefulPopover>
  )
};

export default TextAlignChange;