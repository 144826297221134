import { useState, useEffect, Fragment } from 'react';
// import axios from 'axios';

import * as bucketUtils from '../../../utils/bucket/bucket-util';

import { useStore } from '../../../hook-store/store';

import { BACKEND_URL } from '../../../App';


function ServiceBuckets(props) {

  const [store, dispatch] = useStore();
  const { 
    allBucketObjects,
    isUploadFailed,
   } = store.bucketObjectStore;
  const {
    serviceBucketsDataList, 
    serviceTotalSizeGB,
  } = store.serviceBucketsStore;
  const { gLoading } = store.uiStore;



  // useEffect(() => {
  //   if (serviceTotalSizeGB > 0) {
  //     dispatch('SET_TOTALSIZEGB', serviceTotalSizeGB);
  //   }
  // },[serviceTotalSizeGB]);


  //// set updated total size when objects changed or get sevices data list
  useEffect(() => {
    if (serviceBucketsDataList.length > 0) {
      const sumSizeGB = sumTotalGBs(serviceBucketsDataList);
      dispatch('SET_TOTALSIZEGB', sumSizeGB);
      dispatch('SET_SERVICETOTALSIZEGB', sumSizeGB);
    }
  },[serviceBucketsDataList]);

  // useEffect(() => {
  //   if (serviceBucketsDataList.length > 0) {
  //     const sumSizeGB = sumTotalGBs(serviceBucketsDataList);
  //     dispatch('SET_TOTALSIZEGB', sumSizeGB);
  //     dispatch('SET_SERVICETOTALSIZEGB', sumSizeGB);
  //   }
  // },[serviceBucketsDataList, allBucketObjects]);



  useEffect(() => {
    getServiceBucketsInfo(BACKEND_URL, localStorage.getItem('token'));
  },[allBucketObjects]);

  useEffect(() => {
    if (isUploadFailed) {
      getServiceBucketsInfo(BACKEND_URL, localStorage.getItem('token'));
    }
  },[isUploadFailed]);


  const getServiceBucketsInfo = async (url, token) => {
    try {
      dispatch('SET_GLOADING', true);

      const result = await fetch(url + '/service-buckets/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });

      const resData = await result.json();
      console.log(result, resData, 'service-buckets');
  
      if (!result.ok) {
        throw new Error('error occured');
      }

      
      if (resData.data && resData.data.length > 0) {
        dispatch('SET_SERVICEBUCKETSDATALIST', resData.data);
      }

      dispatch('SET_ISSERVICEBUCKETSAPI', true);

      //// set isUploadFailed false after service info get finish
      dispatch('SET_ISUPLOADFAILED', false);

      dispatch('SET_GLOADING', false);

      return resData;

    

    } catch(err) {
      console.log(err);
      dispatch('SET_ISSERVICEBUCKETSAPI', false);
      dispatch('SET_GLOADING', false);
      throw err;
    }
  };


  const sumTotalGBs = (serviceList) => {
    
    let totalGBs = 0;
    for (const service of serviceList) {
      totalGBs = totalGBs + service.totalSizeGB;
    }

    return totalGBs;
  }
  

  return (
    <Fragment>
    </Fragment>
  );
}

export default ServiceBuckets;
