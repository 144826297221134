import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
// import DesignEditor from "~/views/DesignEditor"
import DesignEditor from "./components/Tools/DesignEditor";
import Dashboard from "~/views/Dashboard"


import AboutPage from './pages/AboutPage/AboutPage';
// // import AdminPage from './pages/AdminPage/AdminPage';
import AuthCheck from './components/Auth/AuthCheck';
import AuthRequired from './components/Auth/AuthRequired';
import Backdrop from './components/Backdrop/Backdrop';
import Bucket from './components/BucketComponents/Bucket/Bucket';
// import BucketObjects from './components/BucketComponents/BucketObject/BucketObjects';
import Footer from './components/Footer/Footer';
import GetToolsAuth from './components/Auth/GetToolsAuth';
import GetLoginUsers from './components/Auth/GetLoginUsers';
import GetWindowData from './components/UI/GetWindowData';
import GetDeferredPrompt from './components/UI/GetDeferredPrompt';
import HeadMetaTag from './components/UI/HeadMetaTag';
import Layout from './components/Layout/Layout';
import Loader from './components/Loader/Loader';
import NotPageFound from './pages/NotPageFound/NotPageFound';
import PrivacyPolicy from './pages/PlivacyPolicy/PrivacyPolicy';
import SizeManagePage from './pages/SizeManagePage/SizeManagePage';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import UserInfo from './pages/UserInfo/UserInfo';

import Register from './pages/Subscription/Register';

import GetAllObjects from './components/BucketComponents/BucketObject/GetAllObjects';
import GetSubscriptionData from './components/subscription/GetSubscriptionData';

import FileUploadSocket from './components/BucketComponents/ObjectControl/FileUploadSocket';
import FileUpdateSocket from './components/Tools/FileUpdateSocket/FileUpdateSocket';

import ServiceBuckets from './components/BucketComponents/ServiceBuckets/ServiceBuckets';


import { 
  getCustomerDataSubscriptions, 
  // getCurrentSubscription, 
  // fetchPrices 
} from './utils/stripe';

import Luckysheet from './components/Tools/Spreadsheet/Luckysheet'
import LoadFile from './components/Tools/LoadFile';
// import SheetFile from './components/Tools/Spreadsheet/SheetFile';
import ToastUiEditor from './components/Tools/MarkdownEditor/ToastUiEditorWYSIWYG';
import QuillEditor from './components/Tools/TextEditor/QuillEditor/QuillEditor';
import ToolsNotPageFound from './pages/NotPageFound/ToolsNotPageFound';

import { useStore } from "./hook-store/store";


import './App.css';

const lsUuid = localStorage.getItem('lsUuid') ? localStorage.getItem('lsUuid') : '';



//// dev urls
// export const BACKEND_URL = 'http://127.0.0.1:4003';
// export const loginPageLink = 'http://localhost:8501' + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = 'http://localhost:8501' + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const subscriptionBasicPriceId = 'price_1MJyehIwXirsfm9gD9c7ctve';
// export const subscriptionMiniPriceId = 'price_1MJyQGIwXirsfm9gGTTnTuD9';
// export const kuraStorageLink = 'https://kura-storage.spaceeight.net';


//// test deploy urls
// export const BACKEND_URL = 'https://dokmp19-bc.spaceeight.net';
// export const loginPageLink = 'https://authsso.spaceeight.net' + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = 'https://authsso.spaceeight.net' + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
// export const subscriptionBasicPriceId = 'price_1MRZjTIwXirsfm9gbYrBwdO5';
// export const subscriptionMiniPriceId = 'price_1MRZffIwXirsfm9gPjWau3HS';
// export const kuraStorageLink = 'https://do-bucket-frontend-app-test.web.app';


//// deploy urls
export const BACKEND_URL = 'https://dokmp19-b.spaceeight.net';
export const loginPageLink = 'https://authsso.spaceeight.net' + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
export const signupPageLink = 'https://authsso.spaceeight.net' + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const SUBSCRIPTION_BACKEND_URL = BACKEND_URL;
export const subscriptionBasicPriceId = 'price_1MRZjTIwXirsfm9gbYrBwdO5';
export const subscriptionMiniPriceId = 'price_1MRZffIwXirsfm9gPjWau3HS';
export const kuraStorageLink = 'https://kura-storage.spaceeight.net';


function App() {

  const [store, dispatch] = useStore();
  console.log('store-in-App.tsx', store);
  const { 
    isAuth, 
    gLoading, 
    isAdmin,
    isAllApis,
    // isBackendApi 
  } = store.uiStore;
  const { isStripeApi } = store.stripeData;
  const { isServiceBucketsApi } = store.serviceBucketsStore;


  const userLoginEmail = localStorage.getItem("email")
  ? localStorage.getItem("email")
  : "";

  useEffect(() => {
    if (isServiceBucketsApi && isStripeApi) {
      dispatch('SET_ISALLAPIS', true);
    } else {
      dispatch('SET_ISALLAPIS', false);
    }
  }, [isStripeApi, isServiceBucketsApi]);


  useEffect(() => {
    const lsCustomerEmail = localStorage.getItem('customerEmail');
    
    if (userLoginEmail && lsCustomerEmail) {
      const token = localStorage.getItem('token');
    
      getCustomerDataSubscriptions(token, userLoginEmail)
        .then(result => {
          console.log('get sub result', result);

          if (result.data.length > 0) {
            const subs = result.data;
            // dispatch('SET_SUBSCRIPTIONS', subs);

            const remeetSubs = subs.filter(sub => {
              return sub.metadata.service === 'kura-storage';
            })

            dispatch('SET_SUBSCRIPTIONS', remeetSubs);
          }

        })
        .catch(err => {
          console.log('get sub error', err);
        });
    }
  }, [userLoginEmail]);


  let routesBody;

  if (isAuth) {
    routesBody = (
      <div>
        {/* <Bucket /> */}

        <Routes>
          {/* <Route path="test-route" 
            element={<div>test-route-content</div>} 
          /> */}
          {/* <Route path="userinfo" 
            element={<UserInfo />} 
          /> */}

          <Route path="privacypolicy" 
            element={<PrivacyPolicy />} 
          />
          <Route path="termsofuse" 
            element={<TermsOfUse />} 
          />
          {/* <Route path="about" 
            element={<AboutPage />} 
          /> */}

   

          {/* {bucketName && isAllApis && (
            <>
              <Route path="userinfo" 
                element={<UserInfo />} 
              />

              <Route path="/" 
                element={<BucketObjects />} 
              />

              <Route path="sizemanage" 
                element={<SizeManagePage />} 
              />

              <Route path="/subscription/prices"
                element={<Prices />}
              />

              <Route path="/subscription/subscribe"
                element={<Subscribe />}
              />
                  
              <Route path="/subscription/change-plan"
                element={<ChangePlan />}
              />

              <Route path="/subscription/account"
                element={<Account />}
              />

              <Route path="/subscription/cancel"
                element={<Cancel />}
              />
            </>
          )} */}

        <Route path="spreadsheet" 
          element={
            <>
              {/* <SheetFile /> */}
              <Luckysheet />
            </>
          } 
        />

        <Route path="markdown-editor" 
          element={<ToastUiEditor />} 
        />

        <Route path="text-editor" 
          element={<QuillEditor />} 
        />

        <Route path="/fileload" 
          element={<LoadFile />} 
        />

        <Route path="/slide" 
          element={<DesignEditor />} 
        />

          <Route path="*" 
            element={<ToolsNotPageFound />}
            // element={<NotPageFound />} 
          />
        </Routes>

      </div>

    )
  }
  else {
    routesBody = (
    <div>
      <Routes>

        {/* <Route path="privacypolicy" 
          element={<PrivacyPolicy />} 
        />
        <Route path="termsofuse" 
          element={<TermsOfUse />} 
        />
        <Route path="about" 
          element={<AboutPage />} 
        /> */}

        {/* <Route path="image-editor" 
          element={<ToastUiImageEditor />} 
        />
         */}

         <Route path="spreadsheet" 
          element={
            <>
              {/* <SheetFile /> */}
              <Luckysheet />
            </>
          } 
        />

        <Route path="markdown-editor" 
          element={<ToastUiEditor />} 
        />

        <Route path="text-editor" 
          element={<QuillEditor />} 
        />
        
        <Route path="/fileload" 
          element={<LoadFile />} 
        />

        <Route path="/slide" 
          element={<DesignEditor />} 
        />
        
        <Route path="*" 
          element={<ToolsNotPageFound />} 
          // element={<NotPageFound />} 
        />
      </Routes>
    </div>
    )
  }


  return (
    <Fragment>
      {gLoading && (
        <div>
          <Backdrop 
            zIndex={'500'}
            onCancel={() => { 
              if (!gLoading) {
                // setClickedObjectKeyHandler(''); 
              }
            }}
          />
          <span className='gLoader'>
            <Loader />
          </span>
        </div>
      )}
      <BrowserRouter>
        {routesBody}

        <Footer />
    

          <GetWindowData />
          <GetDeferredPrompt />
          <HeadMetaTag />

          <GetToolsAuth />
          {/* <AuthCheck /> */}
          
          {/* <div style={{display:'none'}}>
            <GetLoginUsers />
          </div> */}

        {isAuth && (
            <div>
              <Register />
              <GetAllObjects />
              <GetSubscriptionData />

              <FileUploadSocket />
              <FileUpdateSocket />

              <ServiceBuckets />
              <AuthCheck />

              <div style={{display:'none'}}>
                <GetLoginUsers />
              </div>
            </div>
          )}
      </BrowserRouter> 
    </Fragment>

    // <BrowserRouter>
    // {/* <div>app-js</div> */}
    //   <Routes>
    //     <Route path="/manage" element={<Dashboard />} />
    //     <Route path="/" element={<DesignEditor />} />
    //   </Routes>
    // </BrowserRouter>
  )
}

export default App;
