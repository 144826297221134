import React from "react"
import { useEditor } from "@layerhub-io/react"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import { Button, SIZE } from "baseui/button"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import Scrollable from "~/components/Scrollable"
import { graphics } from "~/constants/mock-data"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"

// import PathFill from "./PathFill";
import Gradient from "./Common/Gradient";
import Outline from "./Common/Outline";
import Shadow from "./Common/Shadow";

// @ts-ignore:
import SquareImage from "./ElementsImages/square-128.png";
// @ts-ignore:
import TriangleImage from "./ElementsImages/triangle-128.png";
// @ts-ignore:
import HexagonImage from "./ElementsImages/hexagon-128.png";
// @ts-ignore:
import StarImage from "./ElementsImages/star-17-128.png";
// @ts-ignore:
import BirdImage from "./ElementsImages/twitter-128.png";
// @ts-ignore:
import HeartImage from "./ElementsImages/heart-69-128.png";

const imageList = [
  {name: 'square', image: SquareImage },
  {name: 'triangle', image: TriangleImage },
  {name: 'hexagon', image: HexagonImage },
  {name: 'star', image: StarImage },
  {name: 'bird', image: BirdImage },
  {name: 'heart', image: HeartImage },
];
  

export default function () {
  const editor = useEditor()
  const setIsSidebarOpen = useSetIsSidebarOpen()

  const addObject = React.useCallback(
    (item: any) => {
      if (editor) {
        editor.objects.add(item)
      }
    },
    [editor]
  )

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem",
        }}
      >
        <Block>Elements</Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
      </Block>
      <Scrollable>
        {/* <Block padding={"0 1.5rem"}>
          <Button
            size={SIZE.compact}
            overrides={{
              Root: {
                style: {
                  width: "100%",
                },
              },
            }}
          >
            Computer
          </Button>
        </Block> */}
        <Block>
          <Block $style={{ display: "grid", gap: "8px", padding: "1.5rem", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
            {graphics.map((graphic, index) => {
              
              const imageItem = imageList.find(element => {
                return element.name === graphic.name;
              });

              if (imageItem) {
                return (
                  <ImageItem 
                    onClick={() => addObject(graphic)} 
                    key={index} 
                    // preview={graphic.preview}
                    preview={imageItem.image}
                  />
                )
              } else {
                return null;
              }
            })}
          </Block>
        </Block>
      </Scrollable>
      
      {/* <PathFill /> */}
      {/* <Gradient /> */}
      {/* <Outline /> */}
      {/* <Shadow /> */}

    </Block>
  )
}

function ImageItem({ preview, onClick }: { preview: any; onClick?: (option: any) => void }) {
  const [css] = useStyletron()
  return (
    <div
      onClick={onClick}
      className={css({
        position: "relative",
        background: "#f8f8fb",
        cursor: "pointer",
        // borderRadius: "8px",
        overflow: "hidden",
        ":hover": {
          opacity: 1,
          background: "rgb(233,233,233)",
        },
      })}
    >
      <img
        src={preview}
        className={css({
          width: "100%",
          height: "100%",
          objectFit: "contain",
          pointerEvents: "none",
          verticalAlign: "middle",
        })}
      />
    </div>
  )
}
