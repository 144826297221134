import React, {Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { convert, htmlToText } from 'html-to-text';

import { pdfExporter } from 'quill-to-pdf';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { saveAs } from 'file-saver';

import { jsPDF } from "jspdf";

import { useStore } from '../../../../../hook-store/store';
import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';
// import { 
//   convertHtmlFileToPdf,
//  } from '../../../../../utils/tools-bucket-object/tools-file-convert-util';
// import {
// inlineStyles
// } from '../../../../../utils/tools-bucket-object/quill-file-convert-util';

// import { mimeTypes } from '../../../../../utils/file/mime-type';

// import { BACKEND_URL } from '../../../../../App';

import '../EditorFileMenu.css';

function HtmlToText(props) {
  const { 
    editorRef,
    // editorHtml,
    // setEditorHtml
   } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { loadedData } = store.toolsStore;
  const { gLoading } = store.uiStore;
  const { editorHtml } = store.textEditorStore;

  // const [editorHtml, setEditorHtml] = useState('');
  // const [theme, setTheme] = useState('snow');
  const [isLoading, setIsLoading] = useState(false);
  

 

  async function pdfExport() {
    const quillInstance = editorRef.current.getEditor();
    const quillDelta = quillInstance.getContents(); // gets the Quill delta
    const pdfAsBlob = await pdfExporter.generatePdf(quillDelta); // converts to PDF
    console.log(pdfAsBlob);

    saveAs(pdfAsBlob, 'pdf-export.pdf'); // downloads from the browser
  }

async function jsPdfFn() {
  const quillInstance = editorRef.current.getEditor();
  const quillDelta = quillInstance.getContents(); // gets the Quill delta
  
  const doc = new jsPDF();

  doc.text("Hello world!", 10, 10);
  doc.save("a4.pdf");
  // const pdfAsBlob = await pdfExporter.generatePdf(quillDelta); // converts to PDF
  // console.log(pdfAsBlob);

  // saveAs(pdfAsBlob, 'pdf-export.pdf'); // downloads from the browser
}





  const HtmlToTextConvert = async () => {
    try {
      dispatch('SET_GLOADING', true);
      // setIsLoading(true);

      const quillInstance = editorRef.current.getEditor();
      const quillDelta = quillInstance.getContents(); 
  
      console.log(quillDelta)

      const cfg = {
          // inlineStyles: inlineStyles,
      };
      
      // var converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
      const converter = new QuillDeltaToHtmlConverter(quillDelta.ops, cfg);
      
      var html = converter.convert(); 
      // console.log('html', html);
  
      html = `<!DOCTYPE html><html style="font-family:sans-serif"><head></head><body>` + 
        html + 
        `</body></html>`

      
      const options = {
        // wordwrap: 130,
        wordwrap: false,
        // ...
      };

      const text = convert(html, options);
      // console.log(text)
  
      const fileHtml = createTextFile(text, 'convert-text.txt');
      // // console.log(fileHtml);
  
      const blob = new Blob([fileHtml], { type: 'text/plain' });
      saveAs(blob, 'convert-text.txt');
  
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };

  return (
    <Fragment>
      <button className='editorFileMeunButton'
      // style={{border:"none", backgroundColor:"var(--color)"}}
        disabled={gLoading || !editorHtml || editorHtml === "<p><br></p>"}
        onClick={HtmlToTextConvert}
      >
        {t('textEditorFile.12', 'download as text file')}
      </button>

      {/* {isLoading && (
        <div>
          <Backdrop />
          <Loader />
        </div>
      )} */}
    </Fragment>
    )
}


export default HtmlToText;