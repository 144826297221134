import React from "react"
import { styled } from "baseui"
import { Theme } from "baseui/theme"
import Common from "./Common"
import Scenes from "./Scenes"

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
  background: $theme.colors.white,
  width: "90vw"
}))

const Presentation = () => {
  return (
    <Container>
      <div style={{ width:"100%", overflowX:"auto"}}>
        <Scenes />
      </div>
      <Common />
    </Container>
  )
}

export default Presentation
