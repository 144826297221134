// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HdVLCMzWDT4u4hGtEFWq {\n  /* position:fixed; */\n  top: 0; \n  width: 100%; \n  height: 3rem;\n  /* background: var(--color-gray-100); */\n  background-color: whitesmoke;\n  background-color: rgb(250, 255, 255);\n  box-shadow: var(--shadow-medium);\n  display: flex;\n  justify-content: space-between;\n  /* align-items: center; */\n}\n\n.T29ZHgPYEdL59PkMIu7u {\n  margin: auto 0;\n}", "",{"version":3,"sources":["webpack://./src/components/Navigation/MainNav/MainNav.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,MAAM;EACN,WAAW;EACX,YAAY;EACZ,uCAAuC;EACvC,4BAA4B;EAC5B,oCAAoC;EACpC,gCAAgC;EAChC,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".mainNav {\n  /* position:fixed; */\n  top: 0; \n  width: 100%; \n  height: 3rem;\n  /* background: var(--color-gray-100); */\n  background-color: whitesmoke;\n  background-color: rgb(250, 255, 255);\n  box-shadow: var(--shadow-medium);\n  display: flex;\n  justify-content: space-between;\n  /* align-items: center; */\n}\n\n.mainNavTitle {\n  margin: auto 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainNav": "HdVLCMzWDT4u4hGtEFWq",
	"mainNavTitle": "T29ZHgPYEdL59PkMIu7u"
};
export default ___CSS_LOADER_EXPORT___;
