function Play({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 21H3V5.25C3 4.65326 3.23705 4.08097 3.65901 3.65901C4.08097 3.23705 4.65326 3 5.25 3H18.75C19.3467 3 19.919 3.23705 20.341 3.65901C20.7629 4.08097 21 4.65326 21 5.25V21ZM4.5 19.5H19.5V5.25C19.5 5.05109 19.421 4.86032 19.2803 4.71967C19.1397 4.57902 18.9489 4.5 18.75 4.5H5.25C5.05109 4.5 4.86032 4.57902 4.71967 4.71967C4.57902 4.86032 4.5 5.05109 4.5 5.25V19.5ZM9 16.2922V7.70775L16.512 12L9 16.2922ZM10.5 10.2922V13.7078L13.488 12L10.5 10.2922Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Play
