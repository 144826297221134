// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q04l0Ii2NOEevaCAv8Bn {\n  height: 0.25px;\n  background-color: var(--color-gray-500);\n}\n\n.kNhjptLGehC9RDgKaofl {\n  overflow-x: hidden;\n  text-align: center;\n  padding: 0.5rem 0.5rem;\n}\n\n.RYgCIeu2kY0aQXfUzjBm {\n  padding-bottom: 0.5rem;\n}\n\n.kJfFP1PDOJt3IQotoxAm {\n  font-size: 0.9rem;\n}\n\n.AVmYHIm2JpHOEJJdkuhy {\n  /* cursor: pointer; */\n}", "",{"version":3,"sources":["webpack://./src/components/Auth/GetLoginUsers.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".loginUsersSeparator {\n  height: 0.25px;\n  background-color: var(--color-gray-500);\n}\n\n.loginUsersUser {\n  overflow-x: hidden;\n  text-align: center;\n  padding: 0.5rem 0.5rem;\n}\n\n.loginUsersEmail {\n  padding-bottom: 0.5rem;\n}\n\n.loginUsersSelect {\n  font-size: 0.9rem;\n}\n\n.loginUsersSelectButton {\n  /* cursor: pointer; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginUsersSeparator": "Q04l0Ii2NOEevaCAv8Bn",
	"loginUsersUser": "kNhjptLGehC9RDgKaofl",
	"loginUsersEmail": "RYgCIeu2kY0aQXfUzjBm",
	"loginUsersSelect": "kJfFP1PDOJt3IQotoxAm",
	"loginUsersSelectButton": "AVmYHIm2JpHOEJJdkuhy"
};
export default ___CSS_LOADER_EXPORT___;
