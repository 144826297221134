import React from "react"
import { useActiveObject, useEditor } from "@layerhub-io/react"
// import getSelectionType from "~/utils/get-selection-type"
import { Input } from "baseui/input"
import { Block } from "baseui/block"
import { ChevronDown } from "baseui/icon"
import Common from "../Common"
import TextColor from "~/components/Icons/TextColor"
import Bold from "~/components/Icons/Bold"
import Italic from "~/components/Icons/Italic"
import Underline from "~/components/Icons/Underline"
import TextAlignCenter from "~/components/Icons/TextAlignCenter"

import { Button, SIZE, KIND } from "baseui/button"
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip"
import LetterCase from "~/components/Icons/LetterCase"
import Spacing from "~/components/Icons/Spacing"
import { StatefulPopover } from "baseui/popover"
import TextAlignJustify from "~/components/Icons/TextAlignJustify"
import TextAlignLeft from "~/components/Icons/TextAlignLeft"
import TextAlignRight from "~/components/Icons/TextAlignRight"
import { Slider } from "baseui/slider"
import useAppContext from "~/hooks/useAppContext"
import { FONT_SIZES, SAMPLE_FONTS } from "~/constants/editor"
import getSelectionType from "~/utils/get-selection-type"
import { IStaticText } from "@layerhub-io/types"
import { getTextProperties } from "../../../utils/text"
import { loadFonts } from "~/utils/fonts"
import Scrollbar from "@layerhub-io/react-custom-scrollbar"


const TextSpacingChange = () => {
  const editor = useEditor()
  const activeObject = useActiveObject()
  const [state, setState] = React.useState<{
    charSpacing: number
    lineHeight: number
  }>({ charSpacing: 0, lineHeight: 0 })

  React.useEffect(() => {
    if (activeObject) {
      // @ts-ignore
      const { charSpacing, lineHeight } = activeObject
      setState({ ...state, charSpacing: charSpacing / 10, lineHeight: lineHeight * 10 })
    }
  }, [activeObject])

  const handleChange = (type: string, value: number[]) => {
    if (editor) {
      if (type === "charSpacing") {
        setState({ ...state, [type]: value[0] })

        // @ts-ignore
        editor.objects.update({
          [type]: value[0] * 10,
        })
      } else {
        setState({ ...state, [type]: value[0] })
        // @ts-ignore

        editor.objects.update({
          [type]: value[0] / 10,
        })
      }
    }
  }
  return (
    <StatefulPopover
      showArrow={true}
      placement={PLACEMENT.auto}
      content={() => (
        <Block padding="12px" width="200px" backgroundColor="#ffffff" display="grid" gridGap="8px">
          <Block>
            <Block $style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Block $style={{ fontSize: "14px" }}>Line height</Block>
              <Block width="52px">
                <Input
                  overrides={{
                    Input: {
                      style: {
                        backgroundColor: "#ffffff",
                        textAlign: "center",
                      },
                    },
                    Root: {
                      style: {
                        borderBottomColor: "rgba(0,0,0,0.15)",
                        borderTopColor: "rgba(0,0,0,0.15)",
                        borderRightColor: "rgba(0,0,0,0.15)",
                        borderLeftColor: "rgba(0,0,0,0.15)",
                        borderTopWidth: "1px",
                        borderBottomWidth: "1px",
                        borderRightWidth: "1px",
                        borderLeftWidth: "1px",
                        height: "26px",
                      },
                    },
                    InputContainer: {},
                  }}
                  size={SIZE.mini}
                  onChange={() => {}}
                  value={Math.round(state.lineHeight)}
                />
              </Block>
            </Block>

            <Block>
              <Slider
                overrides={{
                  InnerThumb: () => null,
                  ThumbValue: () => null,
                  TickBar: () => null,
                  Track: {
                    style: {
                      paddingRight: 0,
                      paddingLeft: 0,
                    },
                  },
                  Thumb: {
                    style: {
                      height: "12px",
                      width: "12px",
                    },
                  },
                }}
                min={0}
                max={100}
                // step
                marks={false}
                value={[state.lineHeight]}
                onChange={({ value }) => handleChange("lineHeight", value)}
              />
            </Block>
          </Block>
          <Block>
            <Block $style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Block $style={{ fontSize: "14px" }}>Char spacing</Block>
              <Block width="52px">
                <Input
                  overrides={{
                    Input: {
                      style: {
                        backgroundColor: "#ffffff",
                        textAlign: "center",
                      },
                    },
                    Root: {
                      style: {
                        borderBottomColor: "rgba(0,0,0,0.15)",
                        borderTopColor: "rgba(0,0,0,0.15)",
                        borderRightColor: "rgba(0,0,0,0.15)",
                        borderLeftColor: "rgba(0,0,0,0.15)",
                        borderTopWidth: "1px",
                        borderBottomWidth: "1px",
                        borderRightWidth: "1px",
                        borderLeftWidth: "1px",
                        height: "26px",
                      },
                    },
                    InputContainer: {},
                  }}
                  size={SIZE.mini}
                  onChange={() => {}}
                  value={Math.round(state.charSpacing)}
                />
              </Block>
            </Block>

            <Block>
              <Slider
                overrides={{
                  InnerThumb: () => null,
                  ThumbValue: () => null,
                  TickBar: () => null,
                  Track: {
                    style: {
                      paddingRight: 0,
                      paddingLeft: 0,
                    },
                  },
                  Thumb: {
                    style: {
                      height: "12px",
                      width: "12px",
                    },
                  },
                }}
                min={-20}
                max={100}
                marks={false}
                value={[state.charSpacing]}
                onChange={({ value }) => handleChange("charSpacing", value)}
              />
            </Block>
          </Block>
        </Block>
      )}
    >
      <Block>
        <StatefulTooltip placement={PLACEMENT.left} showArrow={true} accessibilityType="tooltip" content="Spacing">
          <Button size={SIZE.mini} kind={KIND.tertiary}>
            <Spacing size={24} />
          </Button>
        </StatefulTooltip>
      </Block>
    </StatefulPopover>
  )
}

export default TextSpacingChange;