// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E_1KcrIVuaY78kqzN13Q {\n  margin-top: 2rem\n}\n\n.HIe5llNdg7Uno5wcDx0w {\n  margin-bottom: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/components/subscription/prices/PricesDetail.module.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".priceDetailContainer {\n  margin-top: 2rem\n}\n\n.priceDetailInfo {\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceDetailContainer": "E_1KcrIVuaY78kqzN13Q",
	"priceDetailInfo": "HIe5llNdg7Uno5wcDx0w"
};
export default ___CSS_LOADER_EXPORT___;
