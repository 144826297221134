export const inlineStyles = {
  font: {
    'serif': 'font-family: Georgia, Times New Roman, serif',
    'monospace': 'font-family: Monaco, Courier New, monospace',
    'sans-serif': 'font-family: sans-serif',
   //  'monospace': 'font-family: monospace',
  },
  size: {
      'small': 'font-size: 0.75em',
      'large': 'font-size: 1.5em',
      'huge': 'font-size: 2.5em',
      
      '8pt': 'font-size: 8pt',
      '10pt': 'font-size: 10pt',
      '12pt': 'font-size: 12pt',
      '16pt': 'font-size: 16pt',
      '20pt': 'font-size: 20pt',
      '24pt': 'font-size: 24pt',
      '36pt': 'font-size: 36pt',
      '48pt': 'font-size: 48pt',
      '64pt': 'font-size: 64pt',

      // '10px': 'font-size: 10px',
      // '12px': 'font-size: 12px',
      // '16px': 'font-size: 16px',
      // '20px': 'font-size: 20px',
      // '24px': 'font-size: 24px',
      // '32px': 'font-size: 32px',
      // '48px': 'font-size: 48px',
      // '64px': 'font-size: 64px',
  },
  // indent: (value, op) => {
  //    var indentSize = parseInt(value, 10) * 3;
  //    var side = op.attributes['direction'] === 'rtl' ? 'right' : 'left';
  //    return 'padding-' + side + ':' + indentSize + 'em';
  // },
  // direction: (value, op) => {
  //    if (value === 'rtl') {
  //       return 'direction:rtl' + ( op.attributes['align'] ? '' : '; text-align: inherit' );
  //    } else {
  //       return '';
  //    }
  // }
};