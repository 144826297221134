import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../../hook-store/store';
import { s3fileUpdate } from '../../../../../utils/bucket/bucket-upload-util';
import { 
  getLoadObjectInfo, 
  setUpdatedToken,
  getLoadData,
} from '../../../../../utils/tools-bucket-object/tools-bucket-object-util';
import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';

// import { exportExcel } from './ExportExcel/exportExcel';
import { mimeTypes } from '../../../../../utils/file/mime-type';

import { BACKEND_URL } from '../../../../../App';
// import './App.css';
import '../../../TextEditor/FileControl/EditorFileMenu.css';


function SheetFileUpdate() {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { loadedData } = store.toolsStore;
  const { sheetUpdateTime } = store.spreadsheetStore;
  const { isAuth } = store.uiStore;
  const { loadedObjectInfo, loadedToken } = store.toolsStore;

  const [isLoading, setIsLoading] = useState(false);


  let canUpdate = false;
  
  if ((loadedObjectInfo && loadedObjectInfo.shareType === 'write') ||
      (loadedObjectInfo && loadedObjectInfo.bucket === loadedObjectInfo.userId)
  ) {
      canUpdate = true;
  }



  const dataUpdateHandler = async() => {
    try {

      dispatch('SET_GLOADING', true);
      setIsLoading(true);

      const sheetData = window.luckysheet.toJson();
      // const namedData = {
      //   ...sheetData,
      //   title: sheetTitleInput,
      // };

      console.log(sheetData);


      const loadedData = loadedObjectInfo;
      const key = loadedData.key;

      let path = '';
      const keySplitList = key.split('/');
      const fileName = key.split('/')[key.split('/').length -1];
      const timeEndIndex = fileName.indexOf('Z');
      const originalName = fileName.substring(timeEndIndex+2);

      if (keySplitList.length > 1) {
        keySplitList.pop();
        path = keySplitList.join('/') + '/';
      }

      const file = createTextFile(JSON.stringify(sheetData), originalName);

      console.log(file);

      console.log(fileName, originalName, keySplitList);

      // download(file, `lsheet-data.txt`, 'text/plain');
      // return;

      const result = await s3fileUpdate(
        BACKEND_URL,
        loadedToken,
        path,
        key,
        [file],
        loadedData.bucket,
        dispatch,
      );
      
      if (result.data && result.data.data && result.data.data[0]) {

        console.log(result, result.data.data[0]);
        const updatedToken = result.data.data[0].token;
  
        setUpdatedToken(updatedToken);

        const decodedToken = getLoadObjectInfo(updatedToken);

        dispatch('SET_LOADEDOBJECTINFO', decodedToken);
        dispatch('SET_LOADEDTOKEN', updatedToken);

        console.log(decodedToken);
  
        await updateLoadDataHandler(
          BACKEND_URL,
          updatedToken,
          decodedToken.bucket,
          decodedToken.key,
          decodedToken.mimeType,
        );

        dispatch('SET_SHEETUPDATETIME', Date.now());

      }

      dispatch('SET_GLOADING', false);
      setIsLoading(false);

    } catch(err) {
      console.log(err);

      dispatch('SET_GLOADING', false);
      setIsLoading(false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'update file failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };


  const updateLoadDataHandler = async (url, token, bucket, key, mimeType) => {
    try {
      const resData = await getLoadData(url, token, bucket, key, mimeType);

      if (resData.data) {
        dispatch('SET_LOADEDDATA', resData.data);

        // if (resData.data.startsWith('{')) {
        //   navigate('/spreadsheet');
        // }

        // if (resData.data.startsWith('<')) {
        //   navigate('/text-editor');
        // }
      }



    } catch(err) {
      console.log(err);
      throw err;
    }
  };









  return (
    <Fragment>
      <button  className='editorFileMeunButton'
        disabled={isLoading || !canUpdate}
        onClick={dataUpdateHandler}
      >
        {t('sheet05', 'save file')}
      </button>
    </Fragment>
  );
}

export default SheetFileUpdate;
