import React from "react"
import { useActiveObject, useEditor } from "@layerhub-io/react"
// import getSelectionType from "~/utils/get-selection-type"
import { Input } from "baseui/input"
import { Block } from "baseui/block"
import { ChevronDown } from "baseui/icon"
// import Common from "../Common"
// import TextColor from "~/components/Icons/TextColor"
// import Bold from "~/components/Icons/Bold"
// import Italic from "~/components/Icons/Italic"
// import Underline from "~/components/Icons/Underline"
// import TextAlignCenter from "~/components/Icons/TextAlignCenter"

import { Button, SIZE, KIND } from "baseui/button"
// import { StatefulTooltip, PLACEMENT } from "baseui/tooltip"
// import LetterCase from "~/components/Icons/LetterCase"
// import Spacing from "~/components/Icons/Spacing"
import { StatefulPopover } from "baseui/popover"
// import TextAlignJustify from "~/components/Icons/TextAlignJustify"
// import TextAlignLeft from "~/components/Icons/TextAlignLeft"
// import TextAlignRight from "~/components/Icons/TextAlignRight"
// import { Slider } from "baseui/slider"
// import useAppContext from "~/hooks/useAppContext"
import { FONT_SIZES, SAMPLE_FONTS } from "~/constants/editor"
import getSelectionType from "~/utils/get-selection-type"
import { IStaticText } from "@layerhub-io/types"
import { getTextProperties } from "../../../utils/text"
import { loadFonts } from "~/utils/fonts"
import Scrollbar from "@layerhub-io/react-custom-scrollbar"

// interface TextState {
//   color: string
//   bold: boolean
//   italic: boolean
//   underline: boolean
//   family: string
//   styleOptions: StyleOptions
// }

// interface StyleOptions {
//   hasItalic: boolean
//   hasBold: boolean
//   options: any[]
// }

// const initialOptions: TextState = {
//   family: "CoreLang",
//   bold: false,
//   italic: false,
//   underline: false,
//   color: "#00000",
//   styleOptions: {
//     hasBold: true,
//     hasItalic: true,
//     options: [],
//   },
// }

// const createFontFamilyList = (fontList) => {
//   let currentFontFamily = '';

//   const fontFamilyList = [];
//   for (const font of fontList) {
//     currentFontFamily = font.family;

//     const isFamilyExist = fontFamilyList.find(element => {
//       return element.family === currentFontFamily
//     });

//     if (!isFamilyExist) {
//       fontFamilyList.push(font);
//     }
//   }

//   console.log(fontFamilyList);
// };


const TextFontSize = () => {
  const editor = useEditor()
  const activeObject = useActiveObject()
  const [value, setValue] = React.useState(12)

  React.useEffect(() => {
    // @ts-ignore
    if (activeObject && activeObject.type === "StaticText") {
      // @ts-ignore
      setValue(activeObject.fontSize)
    }
  }, [activeObject])
  
  const onChange = (size) => {
    editor.objects.update({ fontSize: size })
    setValue(size)
  }

  return (
    <StatefulPopover
      content={({ close }) => (
        <Scrollbar style={{ height: "320px", width: "90px" }}>
          <Block backgroundColor="#ffffff" padding="10px 0">
            {FONT_SIZES.map((size, index) => (
              <Block
                onClick={() => {
                  onChange(size)
                  close()
                }}
                $style={{
                  height: "32px",
                  fontSize: "14px",
                  cursor: "pointer",
                  padding: "0 20px",
                  display: "flex",
                  alignItems: "center",
                  ":hover": {
                    background: "rgb(243,243,243)",
                  },
                }}
                key={index}
              >
                {size}
              </Block>
            ))}
          </Block>
        </Scrollbar>
      )}
    >
      <Block width="100px">
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          endEnhancer={<ChevronDown size={22} />}
          overrides={{
            Input: {
              style: {
                backgroundColor: "#ffffff",
                paddingRight: 0,
                fontWeight: 500,
                fontFamily: "Uber Move Text",
                fontSize: "14px",
              },
            },
            EndEnhancer: {
              style: {
                paddingRight: "8px",
                paddingLeft: 0,
                backgroundColor: "#ffffff",
              },
            },
            Root: {
              style: {
                paddingRight: 0,
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderRightWidth: "1px",
                borderLeftWidth: "1px",
                borderBottomColor: "rgb(185,185,185)",
                borderTopColor: "rgb(185,185,185)",
                borderRightColor: "rgb(185,185,185)",
                borderLeftColor: "rgb(185,185,185)",
                borderEndEndRadius: "4px",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                borderStartEndRadius: "4px",
                borderBottomLeftRadius: "4px",
                backgroundColor: "#ffffff",
              },
            },
          }}
          type="number"
          size={SIZE.mini}
        />
      </Block>
    </StatefulPopover>
  )
}


export default TextFontSize
