import React, { Fragment, useEffect, useState } from "react"
import { styled, ThemeProvider, DarkTheme } from "baseui"
import { Theme } from "baseui/theme"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { useEditor } from "@layerhub-io/react"
import useEditorType from "~/hooks/useEditorType"
import { IDesign } from "~/interfaces/DesignEditor"

import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import { useStore } from "../../../../../hook-store/store";

import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';

// import SlideFileUpdate from './SlideFileUpdate';
import SlideFileUpdate from './SlideFileUpdate';

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
  height: "64px",
  background: $theme.colors.black,
  // display: "grid",
  display: "flex",
  padding: "0 1.25rem",
  gridTemplateColumns: "380px 1fr 380px",
  alignItems: "center",
}))

const FileSize = () => {
  const { setDisplayPreview, setScenes, setCurrentDesign, currentDesign, scenes } = useDesignEditorContext()
  const editorType = useEditorType()
  const editor = useEditor()
  // const inputFileRef = React.useRef<HTMLInputElement>(null)

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { loadedData } = store.toolsStore;
  const { isAuth, gLoading } = store.uiStore;
  const { slideFileSize } = store.presentationStore;

  // const [loadFileImportStart, setLoadFileImportStart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // useEffect(() => {
  //   if (editor) {
  //     console.log(editor)
  //     getPresentationJSONSize();
  //   }
  // },[editor]);

  useEffect(() => {
    if (currentDesign && currentDesign.id) {
      getPresentationJSONSize();
    }
  },[currentDesign]);




  const getPresentationJSONSize = () => {
    const currentScene = editor.scene.exportToJSON()

    // console.log(currentScene)
    
    const updatedScenes = scenes.map((scn) => {
      if (scn.id === currentScene.id) {
        return {
          // id: currentScene.id,
          // duration: 5000,
          layers: currentScene.layers,
          // name: currentScene.name,
        }
      }
      return {
        // id: scn.id,
        // duration: 5000,
        layers: scn.layers,
        // name: scn.name,
      }
    })

    // console.log(scenes, updatedScenes)

    if (currentDesign) {
      const presentationTemplate: IDesign = {
        id: currentDesign.id,
        type: "PRESENTATION",
        name: currentDesign.name,
        frame: currentDesign.frame,
        scenes: updatedScenes,
        metadata: {
          ...currentDesign.metadata,
        },
        preview: "",
      }
      // console.log(presentationTemplate, currentDesign)
      const file = createTextFile(JSON.stringify(presentationTemplate), 'file.txt');
      // console.log(file);
      if (file.size > 0) {
        // @ts-ignore
        dispatch('SET_SLIDEFILESIZE', file.size);
      }
      // makeDownload(presentationTemplate)
    } else {
      console.log("NO CURRENT DESIGN")
    }
  }


  return (
    <Fragment>
      {slideFileSize > 0 && (
        <span style={{padding:"0.5rem", color:"lightgray", fontFamily:"system-ui"}}>
          <span>
            {t('slideNav05', 'size')}: {numeral(slideFileSize).format('0.0b')}
          </span>
          {' '}
          <span 
            style={{ cursor:"pointer" }}
            onClick={getPresentationJSONSize}
          >
            ({t('slideNav06', 'update size')})
          </span>
        </span>
      )}
      </Fragment>
  )
}

export default FileSize
