import React, {Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { pdfExporter } from 'quill-to-pdf';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { saveAs } from 'file-saver';

import { jsPDF } from "jspdf";

import { useStore } from '../../../../../hook-store/store';
import { createTextFile } from '../../../../../utils/tools-bucket-object/tools-file-util';
import { 
  convertHtmlFileToPdf,
 } from '../../../../../utils/tools-bucket-object/tools-file-convert-util';
import {
inlineStyles
} from '../../../../../utils/tools-bucket-object/quill-file-convert-util';

import { mimeTypes } from '../../../../../utils/file/mime-type';

import { BACKEND_URL } from '../../../../../App';

import '../EditorFileMenu.css';

function PdfFileControl(props) {
  const { 
    editorRef,
    // editorHtml,
    // setEditorHtml
   } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { loadedData } = store.toolsStore;
  const { gLoading, isAuth } = store.uiStore;
  const { editorHtml } = store.textEditorStore;
  const { loadedObjectInfo, loadedToken } = store.toolsStore;

  // const [editorHtml, setEditorHtml] = useState('');
  // const [theme, setTheme] = useState('snow');
  const [isLoading, setIsLoading] = useState(false);
  

 

  async function pdfExport() {
    const quillInstance = editorRef.current.getEditor();
    const quillDelta = quillInstance.getContents(); // gets the Quill delta
    const pdfAsBlob = await pdfExporter.generatePdf(quillDelta); // converts to PDF
    console.log(pdfAsBlob);

    saveAs(pdfAsBlob, 'pdf-export.pdf'); // downloads from the browser
  }

async function jsPdfFn() {
  const quillInstance = editorRef.current.getEditor();
  const quillDelta = quillInstance.getContents(); // gets the Quill delta
  
  const doc = new jsPDF();

  doc.text("Hello world!", 10, 10);
  doc.save("a4.pdf");
  // const pdfAsBlob = await pdfExporter.generatePdf(quillDelta); // converts to PDF
  // console.log(pdfAsBlob);

  // saveAs(pdfAsBlob, 'pdf-export.pdf'); // downloads from the browser
}





  const quillHtmlConvert = async () => {
    try {
      dispatch('SET_GLOADING', true);
      // setIsLoading(true);

      var deltaOps =  [
        {insert: "Hello\n"},
        {insert: "This is colorful", attributes: {color: '#f00'}}
      ];
      
      // var cfg = {};
  
      const quillInstance = editorRef.current.getEditor();
      const quillDelta = quillInstance.getContents(); 
  
      console.log(quillDelta)

      const cfg = {
          inlineStyles: inlineStyles,
      };
      
      // var converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
      const converter = new QuillDeltaToHtmlConverter(quillDelta.ops, cfg);
      
      var html = converter.convert(); 
      // console.log('html', html);
  
      html = `<!DOCTYPE html><html style="font-family:sans-serif"><head></head><body>` + 
        html + 
        `</body></html>`
  
      // console.log(html)
  
      const fileHtml = createTextFile(html, 'quill-html.html');
      // console.log(fileHtml);
  
      const uploadHtml = await convertHtmlFileToPdf(
        BACKEND_URL,
        loadedToken,
        [fileHtml],
        loadedObjectInfo.bucket,
        loadedObjectInfo.key,
      );
  
      // console.log(uploadHtml, uploadHtml.data);
      
      const blob = new Blob([new Uint8Array(uploadHtml.data).buffer], { type: mimeTypes.pdf })
      saveAs(blob, 'pdf-download.pdf');
  
      // const url = window.URL.createObjectURL(new Blob([new Uint8Array(uploadHtml.data).buffer]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'html-to-pdf.pdf');
      // document.body.appendChild(link);
      // link.click();

      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: 'download failed',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };

  return (
    <Fragment>
      {/* <button onClick={undoHandler}>undo-button</button> */}
      {/* <button onClick={redoHandler}>redo-button</button> */}
      
      {/* <button
        onClick={pdfExport}
      >
        quill-pdf-download
      </button>
      
      <button
        onClick={jsPdfFn}
      >
        js-pdf-create
      </button> */}

      <button className='editorFileMeunButton' 
        title={!isAuth && 'Kura Storage account is required to download as pdf file'}
        disabled={!isAuth || gLoading || !editorHtml || editorHtml === "<p><br></p>"}
        onClick={quillHtmlConvert}
      >
        {t('textEditorFile.10', 'pdf download')}
      </button>

      {/* {isLoading && (
        <div>
          <Backdrop />
          <Loader />
        </div>
      )} */}
    </Fragment>
    )
}


export default PdfFileControl;