
export const createTextFile = (html, fileName) => {
  var jsonString = JSON.stringify(html, undefined, 2);
  const blob = new Blob([html], { type: 'text/plain' });
  const file = new File([blob], `${fileName}.txt`, {type: "text/plain"});
  console.log(html, blob, file);

  return file;

  // var contents = fs.readFileSync('./dmv_file_reader.txt').toString()
  // var blob = new Blob([contents], { type: 'text/plain' });
  // var file = new File([blob], "foo.txt", {type: "text/plain"});

  // var link = document.createElement('a');
  // link.download = 'testhtml.txt';
  // // var blob = new Blob([json_string], {type: 'text/plain'});
  // link.href = window.URL.createObjectURL(file);
  // link.click();
}

export const downloadHtlmTextFile = (html, fileName) => {
  // var jsonString = JSON.stringify(html, undefined, 2);
  const blob = new Blob([html], { type: 'text/plain' });
  const file = new File([blob], "htmltext.txt", {type: "text/plain"});
  console.log(blob, file);

  var link = document.createElement('a');
  link.download = `${fileName}.txt`;
  // var blob = new Blob([json_string], {type: 'text/plain'});
  link.href = window.URL.createObjectURL(file);
  link.click();
};


export const addHtmlHandler = (editor, htmlElementString) => {
  // const editor = editorWyRef.current.getInstance();

  console.log(editor.getSelection(), editor.getSelectedText() );
  // editor.insertText(htmlElementString);
  
  editor.exec('customBlock', { info: 'latex' });
  editor.insertText('what you want')
  // function createNode(
  //   type,
  //   attrs,
  //   content,
  // ) {
  //   const wwe = editor.wwEditor;
  //   return wwe.schema.nodes[type].create(attrs, content);
  // }

  // editor.insertHtml = (type, attrs, content) => {
  //     const wwSelection = editor.getSelection();
  //     const node = createNode(type, attrs, content);
  //     const newDoc = editor.wwEditor.schema.nodes.doc.create(null, node);

  //     const { tr } = editor.wwEditor.view.state;
  //     console.log(node, newDoc, tr);

  //     editor.wwEditor.view.dispatch(tr.replaceWith(wwSelection[0], wwSelection[1], newDoc));
  // };

  // editor.insertHtml('image', null, '')
  // editor.insertHtml('paragraph', null, );
  // editor.insertHtml('paragraph', null, );

  // editor.insertCustomBlock = (info, text) => {
  //   // from customBlock.spec.ts/createCustomBlockNode
  //   const wwSelection = editor.getSelection();
  //   const customBlock = editor.wwEditor.schema.nodes.customBlock.create(
  //       { info },
  //       editor.wwEditor.schema.text(text)
  //   );
  //   const newDoc = editor.wwEditor.schema.nodes.doc.create(null, customBlock);

  //   const { tr } = editor.wwEditor.view.state;

  //   editor.wwEditor.view.dispatch(tr.replaceWith(wwSelection[0], wwSelection[1], newDoc));
  // };

  // editor.insertCustomBlock(null, 'abc');

  // editor.changeMode('markdown');
  // const CommandManager = editor.commandManager;
  // const cusCommand = CommandManager.addCommand('markdown', {
  //   name: 'customCommand',
  //   exec: (mde, data) => {
  //     // do some stuff
  //     const cm = mde.getEditor();
  //     const doc = cm.getDoc();
  //     const range = mde.getCurrentRange();

  //     const from = {
  //       line: range.from.line,
  //       ch: range.from.ch,
  //     };

  //     const to = {
  //       line: range.to.line,
  //       ch: range.to.ch,
  //     };

  //     let { text, url } = data;
  //     const replaceText = `<a href="${url}" class="attachment" target="_blank">${text}</a>`;

  //     doc.replaceRange(replaceText, from, to, '+customCommand');
  //   },
  // });

  // editor.addCommand(cusCommand);


  // editor.changeMode('markdown');
  // editor.exec('customCommand', {
  //   url: 'url',
  //   text: 'fileName',
  // });



  editor.changeMode('markdown');
  // console.log(editor.getSelection(), editor.getSelectedText() );
  const mdSelection = editor.getSelection();
  console.log(mdSelection, editor.getSelectedText() );
  console.log(editor.getMarkdown());
  // editor.replaceSelection(htmlElementString, [2,4], [2,5]);
  // editor.insertText(htmlElementString);
  editor.changeMode('wysiwyg');
};