import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_EDITORHTML: (state, payload) => {
      return { 
        // ...state,
        textEditorStore : {
          ...state.textEditorStore,
          editorHtml: payload, 
        }
      };
    },
    SET_TEXTUPDATETIME: (state, payload) => {
      return { 
        // ...state,
        textEditorStore : {
          ...state.textEditorStore,
          textUpdateTime: payload, 
        }
      };
    },
    SET_SHOWABOUTKURASTORAGE: (state, payload) => {
      return { 
        // ...state,
        textEditorStore : {
          ...state.textEditorStore,
          showAboutKuraStorage: payload, 
        }
      };
    },
 

  };

  initStore(actions, {
    textEditorStore: {
      editorHtml: '',
      textUpdateTime: 0,
      showAboutKuraStorage: false,
      // serviceBucketsInfo: null,
    }
  });
};

export default configureStore;
