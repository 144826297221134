import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from "../../../../Backdrop/Backdrop";
// import ModalConfirm from "../../../Modal/ModalConfirm";
import Modal from '../../../../Modal/Modal';
import { useStore } from "../../../../../hook-store/store";

import { kuraStorageLink } from '../../../../../App';
import AboutKuraStorageContent from "../../../AboutKuraStorageContent/AboutKuraStorageContent";
// import AboutKuraStorageContent from "../../../../components/AboutKuraStorageContent/AboutKuraStorageContent";
// import { marks } from "../../../../utils/marks";
// import KuraStorageImage1 from '../../../../../images/kura-storage-ad-image-1.png';
// import KuraStorageImage2 from '../../../../../images/kura-storage-ad-image-2.png';
// import "./SlideAboutKuraStorage.css";

function SlideAboutKuraStorage(props) {
  // const {
  //   // editorRef,
  //   // editorHtml,
  //   // setEditorHtml
  // } = props;

  const [t] = useTranslation('translation');

  // const importRef1 = useRef(null);

  const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;

  return (
    <Fragment>
        <div>
          <Backdrop
            onCancel={() => {
              dispatch('SET_SHOWSLIDEABOUTKURASTORAGE', false);
            }}
          />
          <Modal
            hideButtons={true}
            onClose={() => {
              dispatch('SET_SHOWSLIDEABOUTKURASTORAGE', false);
            }}
          >
            <AboutKuraStorageContent />
          </Modal>
        </div>
    </Fragment>
  );
}

export default SlideAboutKuraStorage;
