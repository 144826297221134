import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';

// import ImportExcel from './ImportExcel';

import { useStore } from '../../../../../hook-store/store';
// import { s3fileUpdate } from '../../../../utils/bucket/bucket-upload-util';
// import { 
//   getLoadObjectInfo, 
//   setUpdatedToken,
//   getLoadData,
// } from '../../../../utils/tools-bucket-object/tools-bucket-object-util';
// import { createTextFile } from '../../../../utils/tools-bucket-object/tools-file-util';

import { exportExcel } from './exportExcel.js';
import { mimeTypes } from '../../../../../utils/file/mime-type';

// import { BACKEND_URL } from '../../../../App';
// import './App.css';
import '../../../TextEditor/FileControl/EditorFileMenu.css';


function ExportExcelButton() {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { loadedData } = store.toolsStore;
  const { isAuth } = store.uiStore;


  const excelExportHandler = async () => {
    try {
      dispatch('SET_GLOADING', true);

      const exportData = await exportExcel(window.luckysheet.getluckysheetfile());
      // console.log(exportData);
      const blob = new Blob([exportData], {
        type: mimeTypes.xlsx,
        // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      // console.log(blob);

      saveAs(blob, 'export-excel.xlsx');

      dispatch('SET_GLOADING', false);
    } catch(err) {
      console.log(err);

      dispatch('SET_GLOADING', false);
    }
  };



  return (
    <Fragment>
        <button  className='editorFileMeunButton'
          onClick={excelExportHandler}
        >
          {t('sheet03', 'download as Excel file')}
        </button>
    </Fragment>
  );
}

export default ExportExcelButton;
