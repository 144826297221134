// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vBkktWy0tGDScJrOP3m0 {\n  position: fixed;\n  z-index: 1;\n  background-color: rgba(0, 0, 0, 0.1);\n  width: 100%;\n  height: 100vh;\n  height: 100%;\n  top: 0;\n  left: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/Backdrop/Backdrop.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,oCAAoC;EACpC,WAAW;EACX,aAAa;EACb,YAAY;EACZ,MAAM;EACN,OAAO;AACT","sourcesContent":[".backdrop {\n  position: fixed;\n  z-index: 1;\n  background-color: rgba(0, 0, 0, 0.1);\n  width: 100%;\n  height: 100vh;\n  height: 100%;\n  top: 0;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "vBkktWy0tGDScJrOP3m0"
};
export default ___CSS_LOADER_EXPORT___;
