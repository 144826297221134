import React from "react"
import { useActiveObject, useEditor } from "@layerhub-io/react"
// import getSelectionType from "~/utils/get-selection-type"
import { Input } from "baseui/input"
import { Block } from "baseui/block"
import { ChevronDown } from "baseui/icon"
// import Common from "../Common"
import TextColor from "~/components/Icons/TextColor"
// import Bold from "~/components/Icons/Bold"
// import Italic from "~/components/Icons/Italic"
// import Underline from "~/components/Icons/Underline"
// import TextAlignCenter from "~/components/Icons/TextAlignCenter"

import { Button, SIZE, KIND } from "baseui/button"
// import { StatefulTooltip, PLACEMENT } from "baseui/tooltip"
// import LetterCase from "~/components/Icons/LetterCase"
// import Spacing from "~/components/Icons/Spacing"
import { StatefulPopover } from "baseui/popover"
// import TextAlignJustify from "~/components/Icons/TextAlignJustify"
// import TextAlignLeft from "~/components/Icons/TextAlignLeft"
// import TextAlignRight from "~/components/Icons/TextAlignRight"
// import { Slider } from "baseui/slider"
// import useAppContext from "~/hooks/useAppContext"
import { FONT_SIZES, SAMPLE_FONTS } from "~/constants/editor"
import getSelectionType from "~/utils/get-selection-type"
import { IStaticText } from "@layerhub-io/types"
import { getTextProperties } from "../../../utils/text"
import { loadFonts } from "~/utils/fonts"
import Scrollbar from "@layerhub-io/react-custom-scrollbar"

import FontSelector from "../../Panels/panelItems/FontSelector";
import TextFill from "../../Panels/panelItems/TextFill";

// interface TextState {
//   color: string
//   bold: boolean
//   italic: boolean
//   underline: boolean
//   family: string
//   styleOptions: StyleOptions
// }

// interface StyleOptions {
//   hasItalic: boolean
//   hasBold: boolean
//   options: any[]
// }

// const initialOptions: TextState = {
//   family: "CoreLang",
//   bold: false,
//   italic: false,
//   underline: false,
//   color: "#00000",
//   styleOptions: {
//     hasBold: true,
//     hasItalic: true,
//     options: [],
//   },
// }





const TextColorChange = (props) => {
  const { state, setState } = props;

  const editor = useEditor()
  const activeObject = useActiveObject()
  const [value, setValue] = React.useState(12)


  React.useEffect(() => {
    // @ts-ignore
    if (activeObject && activeObject.type === "StaticText") {
      // @ts-ignore
      setValue(activeObject.fontSize)
    }
  }, [activeObject])
  
  // const onChange = (size) => {
  //   editor.objects.update({ fontSize: size })
  //   setValue(size)
  // }

  return (
    <StatefulPopover
      content={({ close }) => (
        // <Scrollbar style={{ height: "320px", width: "250px" }}>
        //   <Block backgroundColor="#ffffff" padding="10px 0">
        //   </Block>
        // </Scrollbar>
          <div style={{width: "300px"}}>
            <TextFill 
              close={close}
            />
          </div>
      )}
    >
      <Button 
        // onClick={() => setActiveSubMenu("TextFill")} 
        size={SIZE.mini} kind={KIND.tertiary}
      >
        <TextColor color={state.color} size={22} />
      </Button>
      {/* <Block width="120px">
        <div style={{display:"flex", alignItems:"center", gap:"0.5rem"}}>

          text change
        </div>
      </Block> */}
    </StatefulPopover>
  )
}


export default TextColorChange;
