import React, { Fragment, useEffect } from 'react';

import openSocket from 'socket.io-client';
// import { io } from "socket.io-client";

import { useStore } from '../../../hook-store/store';

import { BACKEND_URL } from '../../../App';
// import classes from './PostSelect.module.css';

function FileUploadSocket(props) {
  const { uploadProgress } = props;

  const [store, dispatch] = useStore();

  useEffect(() => {
    const socket = openSocket(BACKEND_URL);
    // console.log('socket', socket);
    // const socket = io(BACKEND_URL);
    socket.on('upload', data => {
      // console.log('upload socket data', data);

      dispatch('SHOW_NOTIFICATION', {
        status: 'pending',
        title: '',
        message: `Storing... ${data.originalname} ${data.message}`,
      });

      // setTimeout(() => {
      //   dispatch('CLEAR_NOTIFICATION');
      // }, 1000*3);
      
    });
  },[]);


  useEffect(() => {
    if (uploadProgress > 0) {
      dispatch('SHOW_NOTIFICATION', {
        status: 'pending',
        title: '',
        message: `uploading... ${uploadProgress.toFixed(0)}%`,
      });
      if (uploadProgress >= 100) {
        dispatch('SHOW_NOTIFICATION', {
          status: 'pending',
          title: '',
          message: `upload finished, storing...`,
        });
      }
    }
    else {
      dispatch('CLEAR_NOTIFICATION');
    }

  },[uploadProgress]);



 

  return (
    <Fragment></Fragment>
  );
}

export default FileUploadSocket;