import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import AuthRequired from "../../components/Auth/AuthRequired";
import NotApisAvailable from "./NotApisAvailable";

import { useStore } from "../../hook-store/store";

import { marks } from "../../utils/marks";
// import classes from './NotPageFound.module.css';
import './ToolsNotPageFound.css';

const ToolsNotPageFound = (props) => {
  // const { } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth, gLoading, isAllApis } = store.uiStore;
  const { isStripeApi } = store.stripeData;

  useEffect(() => {

  }, []);

  let toolsNotPageFoundBody;

  if (!isAuth) {
    toolsNotPageFoundBody = (
      <div className="toolsNotPageFound">
        <div>
          <p>
            Kura Storage Tools
            {' '}
            {t('notPageFound.03', 'has simple text editor to create documents, and spreadsheet for data manipulation and calculation.')}
            {' '}
            {t('notPageFound.09', 'It also has a simple tool to create slide presentation.')}
          </p>
          <p>
            {t('notPageFound.04', 'It is possible to import Word file or Excel file for editing.')}
          </p>
          <p>
            {t('notPageFound.05', 'After editing these files, it is possibe to download as Word file, PDF file, or Excel file.')} 
            {' '}
            {t('notPageFound.06', 'For storing edited files, there is a file storage service named')}
            {' '}
            <a
              href="https://kura-storage.spaceeight.net/about"
              target="_blank" rel="noopener noreferrer"
            >
            Kura storage.
            </a>
          </p>
          <p>
            <a
              href="https://kura-storage.spaceeight.net/about"
              target="_blank" rel="noopener noreferrer"
            >
              Kura storage
            </a>
            {' '}
            {t('notPageFound.07', 'is simple storage place for your files. You can upload, store, access, and download various kind of files.')}
          </p>

        </div>
        <div>
          <Link to="/text-editor">
            <button className="btnBase">
            {t('notPageFound.02', 'text editor page')}
            </button>
          </Link>
          <Link to="/spreadsheet">
            <button className="btnBase">
              {t('notPageFound.08', 'Spreadsheet page')}
            </button>
          </Link>
          <Link to="/slide">
            <button className="btnBase">
              {t('notPageFound.10', 'Slide page')}
            </button>
          </Link>
        </div>
      </div>
    );
  }


  return (
    <Fragment>
        {toolsNotPageFoundBody}

    </Fragment>
  );
};

export default ToolsNotPageFound;
