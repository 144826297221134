// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uuz_1Mx1WotJMJrIg8tL {\n  margin-top: 4rem;\n  padding: 1rem;\n}\n\n._vw5Gt22lGZrqNknhGUT {\n  padding-bottom: 1rem;\n  font-size: 1.25rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserInfo/UserInfo.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":[".userInfoContent {\n  margin-top: 4rem;\n  padding: 1rem;\n}\n\n.userInfoItem {\n  padding-bottom: 1rem;\n  font-size: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInfoContent": "uuz_1Mx1WotJMJrIg8tL",
	"userInfoItem": "_vw5Gt22lGZrqNknhGUT"
};
export default ___CSS_LOADER_EXPORT___;
