"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrameObject = void 0;
var fabric_1 = require("fabric");
// @ts-ignore
var FrameObject = /** @class */ (function (_super) {
    __extends(FrameObject, _super);
    function FrameObject() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FrameObject.prototype.initialize = function (options) {
        _super.prototype.initialize.call(this, __assign(__assign({}, options), { selectable: false, hasControls: false, lockMovementY: true, lockMovementX: true, strokeWidth: 0, padding: 0, evented: false }));
        return this;
    };
    FrameObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    FrameObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    FrameObject.fromObject = function (options, callback) {
        return callback && callback(new fabric_1.fabric.Frame(options));
    };
    FrameObject.type = "Frame";
    return FrameObject;
}(fabric_1.fabric.Rect));
exports.FrameObject = FrameObject;
fabric_1.fabric.Frame = fabric_1.fabric.util.createClass(FrameObject, {
    type: FrameObject.type,
});
fabric_1.fabric.Frame.fromObject = FrameObject.fromObject;
