import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger,
  // showMenu,
  // SubMenu,
  // hideMenu,
} from "react-contextmenu";


// import { convert, htmlToText } from 'html-to-text';
// import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
// import { saveAs } from 'file-saver';

import EditorFileControl from "./EditorFileSave/EditorFileSave";
import HtmlToText from "./FileConvert/HtmlToText";
import PdfFileControl from "./FileConvert/PdfFileControl";
import WordFileControl from "./FileConvert/WordFileControl";
import WordFileImport from "./FileConvert/WordFileImport";

import { useStore } from "../../../../hook-store/store";
// import { createTextFile } from '../../../../utils/tools-bucket-object/tools-file-util';
import { getOriginalFileNameFromKey } from "../../../../utils/bucket/bucket-object-util";

import "./react-contextmenu.css";

// const menuId1 = 'menuId-1';
// const menuId2 = 'menuId-2';

// const MENU_TYPE = "SIMPLE";
// const menuName = 'menu-name';
// const menuName2 = 'menu-name2';
// const menuId1Title = 'menuId-1-title';
// const menuId2Title = 'menuId-2-title';

// const menuItem1 = 'menu-item1';
// const menuItem2 = 'menu-item2';
// const menuItem3 = 'menu-item3';

function EditorFileMenu(props) {
  const {     
    menuId, 
    tText,
    handleLeftMouseClick,
    disable, 
    editorRef,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { loadedObjectInfo } = store.toolsStore;

  // const { textUpdateTime } = store.textEditorStore;


  function handleClick(e, data) {
    console.log(data);
  }

  function handleOnShow(e) {
    // console.log(e);
  }

  function handleOnHide(e) {
    // console.log(e);

    // const contextMenuEls = document.getElementsByClassName('react-contextmenu');
    // console.log(contextMenuEls);
  }


  let dispFileName;
  
  if (loadedObjectInfo && loadedObjectInfo.key) {
    dispFileName = getOriginalFileNameFromKey(loadedObjectInfo.key);
  }


  return (
    <Fragment>
      <div className="editorFileMenu">
        <ContextMenuTrigger id={menuId} 
          holdToDisplay={1000}
          // holdToDisplay={0}
          disable={disable}
        >
          <span id={menuId + tText} className="well editorFileMenuTitle"
            onClick={(e) => { 
              handleLeftMouseClick(e, menuId, menuId + tText)
            }}
          >
            {t('textEditorFile.11', 'File menu')}
          </span>
        </ContextMenuTrigger>
      </div>

      <ContextMenu 
        id={menuId}
        onShow={handleOnShow}
        onHide={handleOnHide}
        // hideOnLeave={true}
      >
        <div className='react-contextmenu-item'
          style={{ cursor: 'default', paddingBottom:"20px" }}
        >
          {dispFileName}
        </div>
        <MenuItem
          // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
        >
          <WordFileImport
            editorRef={editorRef}
          />
        </MenuItem>
        <MenuItem
          // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
        >
          <WordFileControl
            editorRef={editorRef}
          />
        </MenuItem>
        <MenuItem
          // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
        >
          <PdfFileControl
            editorRef={editorRef}
          />
        </MenuItem>
        <MenuItem
          // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
        >
          <HtmlToText 
            editorRef={editorRef}
          />
        </MenuItem>
        <MenuItem
          // onClick={HtmlToTextConvert} data={{ item: "item 3" }}
        >
          <EditorFileControl
            editorRef={editorRef}
          />
        </MenuItem>
      </ContextMenu>
      
    </Fragment>
  );
}

export default EditorFileMenu;
