// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.72.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MXtus134DS2wrEDs2ROV {\n  position: fixed;\n  bottom: 0;\n  height: 40px;\n  /* height: 3rem; */\n  /* border-top: 0.5px solid gray; */\n}\n\n.KfMDHpOpYcD606VU7uUl {\n  margin-top: 0.5rem;\n  font-size: 0.75rem;\n}\n.XLvvfjwYssm3XOtSDQzZ {\n  padding: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB","sourcesContent":[".footer {\n  position: fixed;\n  bottom: 0;\n  height: 40px;\n  /* height: 3rem; */\n  /* border-top: 0.5px solid gray; */\n}\n\n.footerLinks {\n  margin-top: 0.5rem;\n  font-size: 0.75rem;\n}\n.footerLink {\n  padding: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "MXtus134DS2wrEDs2ROV",
	"footerLinks": "KfMDHpOpYcD606VU7uUl",
	"footerLink": "XLvvfjwYssm3XOtSDQzZ"
};
export default ___CSS_LOADER_EXPORT___;
