import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_SERVICEBUCKETSDATALIST: (state, payload) => {
      return { 
        // ...state,
        serviceBucketsStore : {
          ...state.serviceBucketsStore,
          serviceBucketsDataList: payload, 
        }
      };
    },
    SET_SERVICETOTALSIZEGB: (state, payload) => {
      return { 
        // ...state,
        serviceBucketsStore : {
          ...state.serviceBucketsStore,
          serviceTotalSizeGB: payload, 
        }
      };
    },
    SET_ISSERVICEBUCKETSAPI: (state, payload) => {
      return { 
        // ...state,
        serviceBucketsStore : {
          ...state.serviceBucketsStore,
          isServiceBucketsApi: payload, 
        }
      };
    },
    // SET_SERVICEBUCKETSINFO: (state, payload) => {
    //   return { 
    //     // ...state,
    //     serviceBucketsStore : {
    //       ...state.serviceBucketsStore,
    //       serviceBucketsInfo: payload, 
    //     }
    //   };
    // },

  };

  initStore(actions, {
    serviceBucketsStore: {
      serviceBucketsDataList: [],
      serviceTotalSizeGB: 0,
      isServiceBucketsApi: false,
      // serviceBucketsInfo: null,
    }
  });
};

export default configureStore;
