import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  showMenu,
  hideMenu,
} from "react-contextmenu";

// import ExportExcelButton from './ExportExcel/ExportExcelButton';
// import ImportExcel from './ImportExcel/ImportExcel';
// import SheetFileUpdate from './SheetFileUpdate/SheetFileUpdate';
import SheetFileMenu from './SheetFileMenu';

import { useStore } from '../../../../hook-store/store';
// import { s3fileUpdate } from '../../../../utils/bucket/bucket-upload-util';
// import { 
//   getLoadObjectInfo, 
//   setUpdatedToken,
//   getLoadData,
// } from '../../../../utils/tools-bucket-object/tools-bucket-object-util';
// import { createTextFile } from '../../../../utils/tools-bucket-object/tools-file-util';

// import { exportExcel } from './ExportExcel/exportExcel';
import { mimeTypes } from '../../../../utils/file/mime-type';

import { BACKEND_URL } from '../../../../App';
// import './App.css';
import './SheetFileControl.css';


const sheetMenuId1 = 'sheetMenuId-1';
const sheetMenuId2 = 'sheetMenuId-2';

const tText = '-title';

const sheetMenuIdList = [sheetMenuId1, sheetMenuId2];


function SheetFileControl(props) {
  const { rightDisable } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { loadedData } = store.toolsStore;
  const { sheetUpdateTime } = store.spreadsheetStore;
  const { isAuth } = store.uiStore;

  const [sheetTitle, setSheetTitle] = useState('');
  const [sheetTitleInput, SetSheetTitleInput] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loadedData) {
      console.log(loadedData);
      const ssData = JSON.parse(loadedData);
      console.log(ssData);
      setSheetTitle(ssData.title);
      console.log( window.luckysheet.getAllSheets() );
      console.log( window.luckysheet.getAllSheets().length );
    }
  },[loadedData]);


  let disable = false;
  
  if (rightDisable) {
    disable = true;
  }


  function handleLeftMouseClick(e, id, idTitle) {
    const menuNameEl = document.getElementById(idTitle);
    // console.log(e, menuNameEl);
    
    const openedMenuEls = document.getElementsByClassName('react-contextmenu--visible');
    // console.log(openedMenuEls);

    //// hide menues when opened
    if (openedMenuEls && openedMenuEls.length > 0) {
      for (const mId of sheetMenuIdList) {
          hideMenu({ id: mId });
      }

      return;
    }
    

    if (menuNameEl) {
      const rect = menuNameEl.getBoundingClientRect();
      // console.log(rect);
      // console.log(e);

      // const x = e.clientX;
      // const y = e.clientY;
      const rx = rect.left + 5;
      const ry = rect.top + rect.height;
  
      showMenu({
        // position: { x, y },
        position: { x: rx, y: ry },
        id: id,
      });

      for (const mId of sheetMenuIdList) {
        if (mId !== id) {
          hideMenu({ id: mId });
        }
      }

    }
  }

  return (
    <Fragment>
        <div className='sheetFileControl'>
          <SheetFileMenu 
            menuId={sheetMenuId1}
            tText={tText}
            handleLeftMouseClick={handleLeftMouseClick}
            disable={disable}
            // editorRef={editorRef}
          />

          {sheetUpdateTime > 0 && (
            <span className='sheetFileControlFileUpdateTime'>
              ({t('sheet02', 'last update')} {new Date(sheetUpdateTime).toLocaleTimeString()})
              </span>
          )}
          {/* <button
            onClick={() => {
              console.log( window.luckysheet);
              console.log( window.luckysheet.getAllSheets() );
              console.log( window.luckysheet.toJson() );
            }}
          >
            get-data
          </button> */}
        </div>

        {/* luckysheet-react-app */}
        

        {/* {!isAuth && (
          <ImportExcel />
        )}

        {isAuth && (
          <SheetFileUpdate />
        )}

        <ExportExcelButton /> */}

    </Fragment>
  );
}

export default SheetFileControl;
