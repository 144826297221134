import React, { Fragment } from "react"
import useAppContext from "~/hooks/useAppContext"
import panelItems from "./panelItems"
import useIsSidebarOpen from "~/hooks/useIsSidebarOpen"
import { Block } from "baseui/block"

import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen";
// import Backdrop from "~/components/Backdrop/Backdrop";
import Backdrop from "../../../../Backdrop/Backdrop";

interface State {
  panel: string
}
const PanelsList = () => {
  const [state, setState] = React.useState<State>({ panel: "Text" })
  const isSidebarOpen = useIsSidebarOpen()
  const { activePanel, activeSubMenu } = useAppContext()

  const setIsSidebarOpen = useSetIsSidebarOpen();

  React.useEffect(() => {
    setState({ panel: activePanel })
  }, [activePanel])

  React.useEffect(() => {
    if (activeSubMenu) {
      setState({ panel: activeSubMenu })
    } else {
      setState({ panel: activePanel })
    }
  }, [activeSubMenu])

  // @ts-ignore
  const Component = panelItems[state.panel]

  return (
    <Fragment>
      <Block
        id="EditorPanelItem"
        $style={{
          background: "#ffffff",
          width: isSidebarOpen ? "306px" : 0,
          flex: "none",
          borderRight: "1px solid #d7d8e3",
          display: "flex",
          transition: "ease width 0.1s",
          overflow: "hidden",

          position: "fixed",
          left: 0,
          height: "90%",
          zIndex: 100,
          // overflowY: scroll,
        }}
      >
        {Component && <Component />}
      </Block>
      
      {isSidebarOpen && (
        <Backdrop 
            onCancel={() => { 
              // if (!isLoading) {
              //   setShowCreateFolder(false); 
              // }
              setIsSidebarOpen(false);
            }}
            zIndex={'90'}
          />
      )}
    </Fragment>
  )
}

export default PanelsList
