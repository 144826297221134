import { isExpired, decodeToken } from "react-jwt";
import regeneratorRuntime from "regenerator-runtime";

export const getLoadData = async (url, token, bucket, key, mimeType) => {
  try {
    // setIsLoading(true);

    const result = await fetch(url + `/bucket/object-file?bucket=${bucket}&key=${key}&mimeType=${mimeType}`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    console.log(result);

    let resData;

    if (mimeType && mimeType.split('/')[0] === 'image') {
      resData = await result.blob();
    } 

    // if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    //   resData = await result.blob();
    //   // console.log(resData);
    // }
    
    // else {
    //   resData = await result.json();
    // }

    else {
      resData = await result.blob();

      // const text = await resData.text();
      // console.log(text);
    }

    // const resData = await result.json();
    console.log(result, resData);

    if (result.ok) {
      return resData;
    } else {
      throw new Error('something wrong');
    }

  } catch(err) {
    console.log(err);
    // setIsLoading(false);
    throw err;
  }
};


export const getLoadObjectInfo = (token) => {

  const jwtdecoded = decodeToken(token);

  if (jwtdecoded) {
    // console.log('jwtdecoded', jwtdecoded);
    const key = jwtdecoded.key;
    const bucket = jwtdecoded.bucket;
  
    if (bucket && key && !isExpired(token)) {
      return jwtdecoded;
    }
  }
};


export const setUpdatedToken = (token) => {
    // const currentUrl = new URL(window.location.href);
    // const queryParams = currentUrl.searchParams;
    // console.log(currentUrl);
  
      // const token = queryParams.get('tokenForCasTGT');
  
      localStorage.setItem('tokenForCasTGT', token);
      localStorage.setItem('casTGT', token);
      localStorage.setItem('token', token);
      // localStorage.setItem('TGTexp', queryParams.get('TGTexp'));
      // localStorage.setItem('casUserId', queryParams.get('casUserId'));
  
      // const jwtdecoded = jwt.decode(token);

      const jwtdecoded = decodeToken(token);
      console.log(jwtdecoded);
  
      localStorage.setItem('TGTexp', jwtdecoded.exp);
      const tokenExpire = new Date(jwtdecoded.exp * 1000);
      console.log('tokenExpire', tokenExpire);
      localStorage.setItem('expiryDate', tokenExpire);

};